export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T> | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Currency: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  EmailAddress: { input: string; output: string; }
  HexColorCode: { input: string; output: string; }
  JSON: { input: Record<string, unknown>; output: Record<string, unknown>; }
  JSONObject: { input: Record<string, unknown>; output: Record<string, unknown>; }
  Latitude: { input: number; output: number; }
  Longitude: { input: number; output: number; }
  ObjectID: { input: string; output: string; }
  TimeZone: { input: string; output: string; }
  URL: { input: string; output: string; }
};

export enum ACCOUNTING_JOURNAL_TRANSACTION_TYPE {
  payable = 'payable',
  receivable = 'receivable'
}

export type AccountingJournal = {
  _id: Scalars['ObjectID']['output'];
  _venue: Venue;
  created_at: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  transactions: Array<AccountingJournalTransaction>;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type AccountingJournalInput = {
  date: Scalars['DateTime']['input'];
  transactions: Array<AccountingJournalTransactionInput>;
};

export type AccountingJournalTransaction = {
  amount: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  type: ACCOUNTING_JOURNAL_TRANSACTION_TYPE;
};

export type AccountingJournalTransactionInput = {
  amount: Scalars['Float']['input'];
  title: Scalars['String']['input'];
  type: ACCOUNTING_JOURNAL_TRANSACTION_TYPE;
};

export type AddToOrderInput = {
  items: Array<CreateOrderItemInput>;
  ticket_linebreaks: Array<Scalars['Int']['input']>;
};

export type BulkUpdateCategoriesInput = {
  id: Scalars['ObjectID']['input'];
  input: UpdateCategoryInput;
};

export type BulkUpdateItemsInput = {
  id: Scalars['ObjectID']['input'];
  input: UpdateItemInput;
};

export enum CHECKOUT_WITH_PAY_WAY_PAYMENT_OPTION {
  abapay = 'abapay',
  abapay_deeplink = 'abapay_deeplink',
  bakong = 'bakong'
}

export enum CREATE_PRE_SIGNED_URL_TYPE {
  item = 'item',
  menu = 'menu',
  venue_banner = 'venue_banner',
  venue_logo = 'venue_logo'
}

export enum CUSTOM_REPORT_DISPLAY_TYPE {
  doughnut = 'doughnut',
  table = 'table'
}

export enum CUSTOM_REPORT_VALUE_FIELD {
  adjustment = 'adjustment',
  cancelled_value = 'cancelled_value',
  count = 'count',
  discount = 'discount',
  grossSales = 'grossSales',
  netSales = 'netSales',
  offer = 'offer',
  serviceCharge = 'serviceCharge',
  subtotal = 'subtotal',
  unitPrice = 'unitPrice',
  vat = 'vat'
}

export enum CUSTOM_REPORT_VALUE_TYPE {
  category = 'category',
  item = 'item',
  itemType = 'itemType',
  location = 'location',
  locationGroupTag = 'locationGroupTag',
  offer = 'offer',
  option = 'option',
  paymentType = 'paymentType'
}

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type Category = {
  _clone_from?: Maybe<Category>;
  _id: Scalars['ObjectID']['output'];
  _menu: Menu;
  _pos_items: Array<Item>;
  _qr_items: Array<Item>;
  _venue: Venue;
  clone_from?: Maybe<Scalars['ObjectID']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  is_template: Scalars['Boolean']['output'];
  menu: Scalars['ObjectID']['output'];
  ordering_index: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type CheckoutWithPayWayInput = {
  orderData?: InputMaybe<CreateQrOrderInput>;
  orderId?: InputMaybe<Scalars['ObjectID']['input']>;
  payment_option: CHECKOUT_WITH_PAY_WAY_PAYMENT_OPTION;
};

export type CheckoutWithPayWayResult = {
  abapay_deeplink?: Maybe<Scalars['String']['output']>;
  payway_ref: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type ConsumedRecipeRawMaterialUsed = {
  _raw_material: RawMaterial;
  _unit_of_measurement: Scalars['ObjectID']['output'];
  consumed_quantity: Scalars['Float']['output'];
  consumed_unit_of_measurement: Scalars['ObjectID']['output'];
  raw_material: Scalars['ObjectID']['output'];
  unit_of_measurement: Scalars['ObjectID']['output'];
  unit_of_measurement_metadata: Scalars['JSON']['output'];
};

export type Counter = {
  _id: Scalars['ObjectID']['output'];
  _venue: Venue;
  seq: Scalars['Int']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type CreateCategoryInput = {
  clone_from?: InputMaybe<Scalars['ObjectID']['input']>;
  is_template: Scalars['Boolean']['input'];
  menu: Scalars['ObjectID']['input'];
  ordering_index: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type CreateCustomReportInput = {
  display_type: CUSTOM_REPORT_DISPLAY_TYPE;
  doughnut_configs?: InputMaybe<CustomReportDoughnutInput>;
  table_configs?: InputMaybe<CustomReportTableConfigInput>;
};

export type CreateItemInput = {
  banner_img?: InputMaybe<Scalars['URL']['input']>;
  category: Scalars['ObjectID']['input'];
  clone_from?: InputMaybe<Scalars['ObjectID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extra_quantity: Scalars['Int']['input'];
  is_pos_enabled: Scalars['Boolean']['input'];
  is_qr_enabled: Scalars['Boolean']['input'];
  is_template: Scalars['Boolean']['input'];
  item_id: Scalars['String']['input'];
  listed_price: Scalars['Float']['input'];
  menu: Scalars['ObjectID']['input'];
  minimum_required_price: Scalars['Float']['input'];
  no_service_charge: Scalars['Boolean']['input'];
  no_vat: Scalars['Boolean']['input'];
  option_groups: Array<ItemOptionGroupInput>;
  options: Array<ItemOptionInput>;
  ordering_index: Scalars['Int']['input'];
  original_price: Scalars['Float']['input'];
  original_price_addons: OriginalPriceAddonsInput;
  prep_time: Scalars['Float']['input'];
  printer_tag?: InputMaybe<Scalars['ObjectID']['input']>;
  recipe: Array<RecipeRawMaterialUsedInput>;
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateMenuInput = {
  banner_img?: InputMaybe<Scalars['URL']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateOptionGroupInput = {
  clone_from?: InputMaybe<Scalars['ObjectID']['input']>;
  menu: Scalars['ObjectID']['input'];
  options: Array<OptionGroupOptionInput>;
  title: Scalars['String']['input'];
};

export type CreateOptionInput = {
  clone_from?: InputMaybe<Scalars['ObjectID']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  is_template: Scalars['Boolean']['input'];
  listed_price: Scalars['Float']['input'];
  menu: Scalars['ObjectID']['input'];
  option_id: Scalars['String']['input'];
  original_price: Scalars['Float']['input'];
  original_price_addons: OriginalPriceAddonsInput;
  recipe: Array<RecipeRawMaterialUsedInput>;
  title: Scalars['String']['input'];
};

export type CreateOrderItemInput = {
  _id: Scalars['ObjectID']['input'];
  cancelled_amount: Scalars['Float']['input'];
  cancelled_reason?: InputMaybe<Scalars['String']['input']>;
  category: Scalars['ObjectID']['input'];
  comments?: InputMaybe<Scalars['String']['input']>;
  extra_quantity: Scalars['Int']['input'];
  gross_amount: Scalars['Float']['input'];
  item: Scalars['ObjectID']['input'];
  item_id: Scalars['String']['input'];
  listed_price: Scalars['Float']['input'];
  minimum_required_price: Scalars['Float']['input'];
  net_amount: Scalars['Float']['input'];
  no_service_charge: Scalars['Boolean']['input'];
  no_vat: Scalars['Boolean']['input'];
  options: Array<CreateOrderItemOptionInput>;
  original_price: Scalars['Float']['input'];
  original_price_addons: OriginalPriceAddonsInput;
  prep_time: Scalars['Float']['input'];
  printer_tag?: InputMaybe<Scalars['ObjectID']['input']>;
  quantity: Scalars['Int']['input'];
  recipe: Array<OrderRecipeRawMaterialUsedInput>;
  serving_quantity: Scalars['Int']['input'];
  subtotal: Scalars['Float']['input'];
  subtotal_addons: SubtotalAddOnsInput;
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
  unit_price: Scalars['Float']['input'];
};

export type CreateOrderItemOptionInput = {
  _id: Scalars['ObjectID']['input'];
  cancelled_amount: Scalars['Float']['input'];
  comments?: InputMaybe<Scalars['String']['input']>;
  gross_amount: Scalars['Float']['input'];
  is_hide_from_receipt: Scalars['Boolean']['input'];
  listed_price: Scalars['Float']['input'];
  net_amount: Scalars['Float']['input'];
  option: Scalars['ObjectID']['input'];
  option_id: Scalars['String']['input'];
  original_price: Scalars['Float']['input'];
  original_price_addons: OriginalPriceAddonsInput;
  quantity: Scalars['Int']['input'];
  recipe: Array<OrderRecipeRawMaterialUsedInput>;
  serving_quantity: Scalars['Int']['input'];
  subtotal: Scalars['Float']['input'];
  subtotal_addons: SubtotalAddOnsInput;
  title: Scalars['String']['input'];
  uid: Scalars['String']['input'];
};

export type CreateOrderItemSubtotalAddOnsAdjustmentInput = {
  amount: Scalars['Float']['input'];
};

export type CreateOrderItemSubtotalAddOnsDiscountInput = {
  amount: Scalars['Float']['input'];
  is_divided: Scalars['Boolean']['input'];
  type: DISCOUNT_TYPE;
  value: Scalars['Float']['input'];
};

export type CreateOrderItemSubtotalAddOnsOfferInput = {
  amount: Scalars['Float']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateOrderItemSubtotalAddOnsServiceChargeInput = {
  amount: Scalars['Float']['input'];
  is_included: Scalars['Boolean']['input'];
  percentage: Scalars['Float']['input'];
};

export type CreateOrderItemSubtotalAddOnsVatInput = {
  amount: Scalars['Float']['input'];
  is_included: Scalars['Boolean']['input'];
  percentage: Scalars['Float']['input'];
};

export type CreateOrderPaymentCashRecordInput = {
  amount: Scalars['Float']['input'];
  code: Scalars['String']['input'];
};

export type CreateOrderPaymentRecordInput = {
  amount: Scalars['Float']['input'];
  code: Scalars['String']['input'];
  kpay_data?: InputMaybe<Scalars['JSON']['input']>;
  payment_type: Scalars['String']['input'];
  payway_data?: InputMaybe<Scalars['JSON']['input']>;
};

export type CreateOrderPaymentRoomMasterInput = {
  folio_id: Scalars['String']['input'];
  folio_number: Scalars['String']['input'];
  room_number: Scalars['String']['input'];
};

export type CreatePosOrderInput = {
  cash_received: Array<CreateOrderPaymentCashRecordInput>;
  cash_returned: Array<CreateOrderPaymentCashRecordInput>;
  customer?: InputMaybe<Scalars['ObjectID']['input']>;
  headcount: Scalars['Int']['input'];
  isInternalReceipt: Scalars['Boolean']['input'];
  items: Array<CreateOrderItemInput>;
  location: Scalars['ObjectID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  payment_types: Array<CreateOrderPaymentRecordInput>;
  room_master_reference?: InputMaybe<CreateOrderPaymentRoomMasterInput>;
  ticket_linebreaks: Array<Scalars['Int']['input']>;
  tips: Array<CreateOrderPaymentRecordInput>;
};

export type CreatePreSignedUrlInput = {
  contentType: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  type: CREATE_PRE_SIGNED_URL_TYPE;
};

export type CreateQrNotificationInput = {
  content?: InputMaybe<Scalars['String']['input']>;
};

export type CreateQrOrderInput = {
  customer?: InputMaybe<Scalars['ObjectID']['input']>;
  items: Array<CreateOrderItemInput>;
  note?: InputMaybe<Scalars['String']['input']>;
  payment_types: Array<CreateOrderPaymentRecordInput>;
};

export type CreateRawMaterialCategoryInput = {
  name: Scalars['String']['input'];
};

export type CreateRawMaterialInput = {
  category: Scalars['ObjectID']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiration_notification_threshold: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  raw_price: Scalars['Float']['input'];
  replenishment_tolerance: Scalars['Float']['input'];
  required_in_stock_amount: Scalars['Float']['input'];
  shelf_life: Scalars['Float']['input'];
  storage_location?: InputMaybe<Scalars['String']['input']>;
  supplier: Scalars['ObjectID']['input'];
  unit_of_measurement: Scalars['ObjectID']['input'];
};

export type CreateRawMaterialReceivedInput = {
  adjustment_quantity: Scalars['Float']['input'];
  defect_quantity: Scalars['Float']['input'];
  expired_quantity: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  raw_material: Scalars['ObjectID']['input'];
  raw_price: Scalars['Float']['input'];
  received_unit_of_measurement: Scalars['String']['input'];
  supplier: Scalars['ObjectID']['input'];
  transaction_date: Scalars['DateTime']['input'];
  unit_of_measurement_metadata: RawMaterialUnitOfMeasurementInput;
  wastage_quantity: Scalars['Float']['input'];
};

export type CreateRawMaterialSupplierInput = {
  code: Scalars['String']['input'];
  contact_person: Scalars['String']['input'];
  country_of_origin: Scalars['String']['input'];
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
};

export type CreateRawMaterialUnitOfMeasurementInput = {
  sub_units: Array<RawMaterialUnitOfMeasurementSubUnitInput>;
  title: Scalars['String']['input'];
};

export type CustomReport = {
  _id: Scalars['ObjectID']['output'];
  created_at: Scalars['DateTime']['output'];
  display_type: CUSTOM_REPORT_DISPLAY_TYPE;
  doughnut_configs?: Maybe<DoughnutConfigs>;
  is_deleted: Scalars['Boolean']['output'];
  table_configs?: Maybe<TableConfigs>;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type CustomReportDoughnutInput = {
  title: Scalars['String']['input'];
  values: Array<CustomReportDoughnutValuesInput>;
};

export type CustomReportDoughnutValuesInput = {
  field: CUSTOM_REPORT_VALUE_FIELD;
  item: Scalars['String']['input'];
  label: Scalars['String']['input'];
  type: CUSTOM_REPORT_VALUE_TYPE;
};

export type CustomReportTableConfigInput = {
  title: Scalars['String']['input'];
  values: Array<TableConfigValuesInput>;
};

export type Customer = {
  _id: Scalars['ObjectID']['output'];
  _venue: Venue;
  created_at: Scalars['DateTime']['output'];
  first_name: Scalars['String']['output'];
  last_name: Scalars['String']['output'];
  linked_socials: CustomerLinkedSocials;
  phone_number?: Maybe<Scalars['String']['output']>;
  uid?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type CustomerInput = {
  first_name: Scalars['String']['input'];
  last_name: Scalars['String']['input'];
  linked_socials: CustomerLinkedSocialsInput;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  uid?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerLinkedSocials = {
  telegram: Scalars['Boolean']['output'];
  wechat: Scalars['Boolean']['output'];
  whatsapp: Scalars['Boolean']['output'];
};

export type CustomerLinkedSocialsInput = {
  telegram: Scalars['Boolean']['input'];
  wechat: Scalars['Boolean']['input'];
  whatsapp: Scalars['Boolean']['input'];
};

export enum DATA_EVENT_TYPE {
  create = 'create',
  delete = 'delete',
  update = 'update'
}

export enum DISCOUNT_TYPE {
  amount = 'amount',
  percentage = 'percentage'
}

export type DateInput = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type DeleteRawMaterialReceivedInput = {
  _id: Scalars['ObjectID']['input'];
  adjustment_quantity: Scalars['Float']['input'];
  defect_quantity: Scalars['Float']['input'];
  expired_quantity: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  raw_material: Scalars['ObjectID']['input'];
  wastage_quantity: Scalars['Float']['input'];
};

export type Device = {
  _active_orders: Array<Order>;
  _id: Scalars['ObjectID']['output'];
  created_at: Scalars['DateTime']['output'];
  fcm_tokens: Array<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type DoughnutConfigValues = {
  field: CUSTOM_REPORT_VALUE_FIELD;
  item: Scalars['String']['output'];
  label: Scalars['String']['output'];
  type: CUSTOM_REPORT_VALUE_TYPE;
};

export type DoughnutConfigs = {
  title: Scalars['String']['output'];
  values: Array<DoughnutConfigValues>;
};

export type FolioList = {
  Balance: Scalars['String']['output'];
  ChargeLimit: Scalars['String']['output'];
  CurrencyCode: Scalars['String']['output'];
  FolioId: Scalars['String']['output'];
  GuestName: Scalars['String']['output'];
};

export enum GEOLOCATION_SHAPE_TYPE {
  circle = 'circle',
  rectangle = 'rectangle',
  square = 'square'
}

export enum GEOLOCATION_TYPE {
  custom = 'custom',
  location = 'location'
}

export type Geolocation = {
  _id: Scalars['ObjectID']['output'];
  _location?: Maybe<Location>;
  _locations_groups_tag?: Maybe<Tag>;
  _venue: Venue;
  created_at: Scalars['DateTime']['output'];
  location?: Maybe<Scalars['ObjectID']['output']>;
  locations_groups_tag?: Maybe<Scalars['ObjectID']['output']>;
  shape: GeolocationShape;
  type: GEOLOCATION_TYPE;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type GeolocationInput = {
  location?: InputMaybe<Scalars['ObjectID']['input']>;
  locations_groups_tag?: InputMaybe<Scalars['ObjectID']['input']>;
  shape: GeolocationShapeInput;
  type: GEOLOCATION_TYPE;
};

export type GeolocationShape = {
  center: Array<Scalars['Float']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  radius?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  type: GEOLOCATION_SHAPE_TYPE;
  width?: Maybe<Scalars['Float']['output']>;
};

export type GeolocationShapeInput = {
  center: Array<Scalars['Float']['input']>;
  height?: InputMaybe<Scalars['Float']['input']>;
  radius?: InputMaybe<Scalars['Float']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  type: GEOLOCATION_SHAPE_TYPE;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type GetCustomersResponse = {
  items: Array<Customer>;
  total: Scalars['Int']['output'];
};

export type GetOperationalOrdersResponse = {
  placed: Array<Order>;
  preparing: Array<Order>;
  processed: Array<Order>;
};

export type InventoryTransactionsSummary = {
  byRawMaterials: Array<InventoryTransactionsSummaryByRawMaterial>;
};

export type InventoryTransactionsSummaryByRawMaterial = {
  _raw_material: RawMaterial;
  raw_material: Scalars['ObjectID']['output'];
  total_adjustment_quantity: Scalars['Float']['output'];
  total_balance_quantity: Scalars['Float']['output'];
  total_consumed_quantity: Scalars['Float']['output'];
  total_defect_quantity: Scalars['Float']['output'];
  total_expired_quantity: Scalars['Float']['output'];
  total_received_quantity: Scalars['Float']['output'];
  total_wastage_quantity: Scalars['Float']['output'];
};

export type Item = {
  _category: Category;
  _clone_from?: Maybe<Item>;
  _id: Scalars['ObjectID']['output'];
  _menu: Menu;
  _printer_tag?: Maybe<Tag>;
  _venue: Venue;
  banner_img?: Maybe<Scalars['URL']['output']>;
  category: Scalars['ObjectID']['output'];
  clone_from?: Maybe<Scalars['ObjectID']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  extra_quantity: Scalars['Int']['output'];
  is_pos_enabled: Scalars['Boolean']['output'];
  is_qr_enabled: Scalars['Boolean']['output'];
  is_template: Scalars['Boolean']['output'];
  item_id: Scalars['String']['output'];
  listed_price: Scalars['Float']['output'];
  menu: Scalars['ObjectID']['output'];
  minimum_required_price: Scalars['Float']['output'];
  no_service_charge: Scalars['Boolean']['output'];
  no_vat: Scalars['Boolean']['output'];
  option_groups: Array<ItemOptionGroup>;
  options: Array<ItemOption>;
  ordering_index: Scalars['Int']['output'];
  original_price: Scalars['Float']['output'];
  original_price_addons: OriginalPriceAddons;
  prep_time: Scalars['Float']['output'];
  printer_tag?: Maybe<Scalars['ObjectID']['output']>;
  recipe: Array<RecipeRawMaterialUsed>;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type ItemOption = {
  _option: Option;
  is_hide_from_receipt: Scalars['Boolean']['output'];
  option: Scalars['ObjectID']['output'];
  ordering_index: Scalars['Float']['output'];
};

export type ItemOptionGroup = {
  _option_group: OptionGroup;
  is_required: Scalars['Boolean']['output'];
  maximum_select: Scalars['Float']['output'];
  minimum_select: Scalars['Float']['output'];
  option_group: Scalars['ObjectID']['output'];
  options: Array<ItemOptionGroupOption>;
  ordering_index: Scalars['Float']['output'];
};

export type ItemOptionGroupInput = {
  is_required: Scalars['Boolean']['input'];
  maximum_select: Scalars['Float']['input'];
  minimum_select: Scalars['Float']['input'];
  option_group: Scalars['ObjectID']['input'];
  options: Array<ItemOptionGroupOptionInput>;
  ordering_index: Scalars['Float']['input'];
};

export type ItemOptionGroupOption = {
  _option: Option;
  is_auto_select: Scalars['Boolean']['output'];
  is_hide_from_receipt: Scalars['Boolean']['output'];
  option: Scalars['ObjectID']['output'];
  ordering_index: Scalars['Float']['output'];
};

export type ItemOptionGroupOptionInput = {
  is_auto_select: Scalars['Boolean']['input'];
  is_hide_from_receipt: Scalars['Boolean']['input'];
  option: Scalars['ObjectID']['input'];
  ordering_index: Scalars['Float']['input'];
};

export type ItemOptionInput = {
  is_hide_from_receipt: Scalars['Boolean']['input'];
  option: Scalars['ObjectID']['input'];
  ordering_index: Scalars['Float']['input'];
};

export enum LOCATION_STATUS {
  occupied = 'occupied',
  open = 'open'
}

export enum LOCATION_TYPE {
  pick_up_point = 'pick_up_point',
  table = 'table'
}

export type Location = {
  _current_orders: Array<Order>;
  _id: Scalars['ObjectID']['output'];
  _locations_groups_tags: Array<Tag>;
  _venue: Venue;
  created_at: Scalars['DateTime']['output'];
  default_order_note?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  hash: Scalars['String']['output'];
  hidden_items_configs: LocationHiddenItemsConfigs;
  is_pay_later_allowed: Scalars['Boolean']['output'];
  is_pay_now_allowed: Scalars['Boolean']['output'];
  locations_groups_tags: Array<Scalars['ObjectID']['output']>;
  name: Scalars['String']['output'];
  no_service_charge: Scalars['Boolean']['output'];
  no_vat: Scalars['Boolean']['output'];
  payment_types: Array<Scalars['String']['output']>;
  room_number?: Maybe<Scalars['String']['output']>;
  status: LOCATION_STATUS;
  type: LOCATION_TYPE;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type LocationEvent = {
  id: Scalars['ObjectID']['output'];
  payload?: Maybe<Location>;
  type: DATA_EVENT_TYPE;
};

export type LocationHiddenItemsConfigs = {
  _categories: Array<Category>;
  _items: Array<Item>;
  categories: Array<Scalars['ObjectID']['output']>;
  is_categories_hide_on_pos: Scalars['Boolean']['output'];
  is_categories_hide_on_qr: Scalars['Boolean']['output'];
  is_item_types_hide_on_pos: Scalars['Boolean']['output'];
  is_item_types_hide_on_qr: Scalars['Boolean']['output'];
  is_items_hide_on_pos: Scalars['Boolean']['output'];
  is_items_hide_on_qr: Scalars['Boolean']['output'];
  item_types: Array<Scalars['String']['output']>;
  items: Array<Scalars['ObjectID']['output']>;
};

export type LocationInput = {
  default_order_note?: InputMaybe<Scalars['String']['input']>;
  is_pay_later_allowed: Scalars['Boolean']['input'];
  is_pay_now_allowed: Scalars['Boolean']['input'];
  locations_groups_tags: Array<Scalars['ObjectID']['input']>;
  name: Scalars['String']['input'];
  no_service_charge: Scalars['Boolean']['input'];
  no_vat: Scalars['Boolean']['input'];
  payment_types: Array<Scalars['String']['input']>;
  room_number?: InputMaybe<Scalars['String']['input']>;
  type: LOCATION_TYPE;
};

export type LoginInput = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type LoginPayload = {
  role: Role;
  token: Scalars['String']['output'];
};

export type LoginSilentInput = {
  token: Scalars['String']['input'];
};

export type Menu = {
  _categories: Array<Category>;
  _id: Scalars['ObjectID']['output'];
  _venue: Venue;
  banner_img?: Maybe<Scalars['URL']['output']>;
  created_at: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type MenuEvent = {
  id: Scalars['ObjectID']['output'];
  payload: Menu;
  type: DATA_EVENT_TYPE;
};

export type Mutation = {
  addToOrder: Order;
  bulkUpdateCategories: Array<Category>;
  bulkUpdateItems: Array<Item>;
  cancelOrderById: Order;
  checkoutWithPayWay: CheckoutWithPayWayResult;
  clearLocation: Location;
  confirmPaymentTypeForOrder: Order;
  confirmPrepTimeForOrder: Order;
  createAccountingJournal: AccountingJournal;
  createCategory: Category;
  createCustomReport: CustomReport;
  createCustomer: Customer;
  createGeolocation: Geolocation;
  createItem: Item;
  createLocation: Location;
  createMenu: Menu;
  createNewDevice: Device;
  createOffer: Offer;
  createOption: Option;
  createOptionGroup: OptionGroup;
  createPosOrder: Order;
  createPreSignedUrl: PreSignedUrlResponse;
  createQrNotification: Notification;
  createQrOrder: Order;
  createRawMaterial: RawMaterial;
  createRawMaterialCategory: RawMaterialCategory;
  createRawMaterialReceived: RawMaterialReceived;
  createRawMaterialSupplier: RawMaterialSupplier;
  createRawMaterialUnitOfMeasurement: RawMaterialUnitOfMeasurement;
  createReservation: Reservation;
  createTag: Tag;
  deleteCategory: Category;
  deleteCustomReport: CustomReport;
  deleteGeolocation: Geolocation;
  deleteItem: Item;
  deleteLocation: Location;
  deleteOption: Option;
  deleteOptionGroup: OptionGroup;
  deleteRawMaterialReceived: RawMaterialReceived;
  deleteReservation: Reservation;
  folioInquiry: RoomMasterResponse;
  login: LoginPayload;
  loginSilent: LoginPayload;
  moveItemsFromOldLocationToNewLocation: Scalars['Boolean']['output'];
  payForLocation: Scalars['Boolean']['output'];
  payForOrder: Scalars['Boolean']['output'];
  printCancelledTicketsByOrder: Scalars['Boolean']['output'];
  printChecklistByAllOrders: Scalars['Boolean']['output'];
  printChecklistByOrder: Scalars['Boolean']['output'];
  printInvoiceByLocation: Scalars['Boolean']['output'];
  printInvoiceByOrder: Scalars['Boolean']['output'];
  printKitchenMessageByItem: Scalars['Boolean']['output'];
  printKitchenMessageByLocation: Scalars['Boolean']['output'];
  printKitchenMessageByOption: Scalars['Boolean']['output'];
  printKitchenMessageByOrder: Scalars['Boolean']['output'];
  printPrintLog: Scalars['Boolean']['output'];
  printTicketsByOrder: Scalars['Boolean']['output'];
  publishPaymentTypeAcceptedForOrder: Scalars['Boolean']['output'];
  publishPrepTimeUpdatedForOrder: Scalars['Boolean']['output'];
  publishPrintEvent: Scalars['Boolean']['output'];
  publishSystemHealthEvent: Scalars['Boolean']['output'];
  resolveAssistance: Notification;
  splitItemsIntoNewOrder: Order;
  updateAccountingJournal: AccountingJournal;
  updateCategory: Category;
  updateCurrentDevice: Device;
  updateCurrentRawMaterial: RawMaterial;
  updateCurrentRawMaterialCategory: RawMaterialCategory;
  updateCurrentRawMaterialReceived: RawMaterialReceived;
  updateCurrentRawMaterialSupplier: RawMaterialSupplier;
  updateCurrentVenue: Venue;
  updateCurrentVenueAddOns: Venue;
  updateCustomReport: CustomReport;
  updateCustomer: Customer;
  updateGeolocation: Geolocation;
  updateItem: Item;
  updateItemRecipe: Item;
  updateLocation: Location;
  updateMenu: Menu;
  updateOffer: Offer;
  updateOption: Option;
  updateOptionGroup: OptionGroup;
  updateOptionRecipe: Option;
  updateOrderById: Order;
  updateOrderStatusById: Order;
  updatePrintLog: PrintLog;
  updateRawMaterialStatus: RawMaterial;
  updateRawMaterialUnitOfMeasurement: RawMaterialUnitOfMeasurement;
  updateReservation: Reservation;
  updateRole: Role;
  updateTag: Tag;
  updateVenueReceiptConfig?: Maybe<VenueReceiptConfig>;
  uploadRawMaterialReceived: Array<RawMaterialReceived>;
  voidReceipt: PrintLog;
};


export type MutationaddToOrderArgs = {
  id: Scalars['ObjectID']['input'];
  input: AddToOrderInput;
};


export type MutationbulkUpdateCategoriesArgs = {
  inputs: Array<BulkUpdateCategoriesInput>;
};


export type MutationbulkUpdateItemsArgs = {
  inputs: Array<BulkUpdateItemsInput>;
};


export type MutationcancelOrderByIdArgs = {
  id: Scalars['ObjectID']['input'];
  message: Scalars['String']['input'];
};


export type MutationcheckoutWithPayWayArgs = {
  input: CheckoutWithPayWayInput;
};


export type MutationclearLocationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationconfirmPaymentTypeForOrderArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationconfirmPrepTimeForOrderArgs = {
  id: Scalars['ObjectID']['input'];
  isAccepted: Scalars['Boolean']['input'];
};


export type MutationcreateAccountingJournalArgs = {
  input: AccountingJournalInput;
};


export type MutationcreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationcreateCustomReportArgs = {
  input: CreateCustomReportInput;
};


export type MutationcreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationcreateGeolocationArgs = {
  input: GeolocationInput;
};


export type MutationcreateItemArgs = {
  input: CreateItemInput;
};


export type MutationcreateLocationArgs = {
  input: LocationInput;
};


export type MutationcreateMenuArgs = {
  input: CreateMenuInput;
};


export type MutationcreateOfferArgs = {
  input: OfferInput;
};


export type MutationcreateOptionArgs = {
  input: CreateOptionInput;
};


export type MutationcreateOptionGroupArgs = {
  input: CreateOptionGroupInput;
};


export type MutationcreatePosOrderArgs = {
  input: CreatePosOrderInput;
};


export type MutationcreatePreSignedUrlArgs = {
  input: CreatePreSignedUrlInput;
};


export type MutationcreateQrNotificationArgs = {
  input: CreateQrNotificationInput;
};


export type MutationcreateQrOrderArgs = {
  input: CreateQrOrderInput;
};


export type MutationcreateRawMaterialArgs = {
  input: CreateRawMaterialInput;
};


export type MutationcreateRawMaterialCategoryArgs = {
  input: CreateRawMaterialCategoryInput;
};


export type MutationcreateRawMaterialReceivedArgs = {
  input: CreateRawMaterialReceivedInput;
};


export type MutationcreateRawMaterialSupplierArgs = {
  input: CreateRawMaterialSupplierInput;
};


export type MutationcreateRawMaterialUnitOfMeasurementArgs = {
  input: CreateRawMaterialUnitOfMeasurementInput;
};


export type MutationcreateReservationArgs = {
  input: ReservationInput;
};


export type MutationcreateTagArgs = {
  input: TagInput;
};


export type MutationdeleteCategoryArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationdeleteCustomReportArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationdeleteGeolocationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationdeleteItemArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationdeleteLocationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationdeleteOptionArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationdeleteOptionGroupArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationdeleteRawMaterialReceivedArgs = {
  input: DeleteRawMaterialReceivedInput;
};


export type MutationdeleteReservationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationfolioInquiryArgs = {
  roomNumber: Scalars['String']['input'];
};


export type MutationloginArgs = {
  input: LoginInput;
};


export type MutationmoveItemsFromOldLocationToNewLocationArgs = {
  newLocationId: Scalars['ObjectID']['input'];
  oldLocationId: Scalars['ObjectID']['input'];
  partialOrders: Array<OrderWithSplittedItemsInput>;
  wholeOrders: Array<Scalars['ObjectID']['input']>;
};


export type MutationpayForLocationArgs = {
  id: Scalars['ObjectID']['input'];
  input: PaymentInput;
};


export type MutationpayForOrderArgs = {
  id: Scalars['ObjectID']['input'];
  input: PaymentInput;
};


export type MutationprintCancelledTicketsByOrderArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationprintChecklistByAllOrdersArgs = {
  ids: Array<Scalars['ObjectID']['input']>;
};


export type MutationprintChecklistByOrderArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationprintInvoiceByLocationArgs = {
  id: Scalars['ObjectID']['input'];
  splitCount?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationprintInvoiceByOrderArgs = {
  id: Scalars['ObjectID']['input'];
  splitCount?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationprintKitchenMessageByItemArgs = {
  item_id: Scalars['ObjectID']['input'];
  message: Scalars['String']['input'];
  order_id: Scalars['ObjectID']['input'];
};


export type MutationprintKitchenMessageByLocationArgs = {
  id: Scalars['ObjectID']['input'];
  message: Scalars['String']['input'];
};


export type MutationprintKitchenMessageByOptionArgs = {
  item_id: Scalars['ObjectID']['input'];
  message: Scalars['String']['input'];
  option_id: Scalars['ObjectID']['input'];
  order_id: Scalars['ObjectID']['input'];
};


export type MutationprintKitchenMessageByOrderArgs = {
  id: Scalars['ObjectID']['input'];
  message: Scalars['String']['input'];
};


export type MutationprintPrintLogArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationprintTicketsByOrderArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationpublishPaymentTypeAcceptedForOrderArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationpublishPrepTimeUpdatedForOrderArgs = {
  id: Scalars['ObjectID']['input'];
  prepTime: Scalars['Float']['input'];
};


export type MutationpublishPrintEventArgs = {
  input: PublishPrintEventInput;
};


export type MutationpublishSystemHealthEventArgs = {
  type: SYSTEM_HEALTH_SUBSCRIPTION_EVENT_TYPE;
};


export type MutationresolveAssistanceArgs = {
  id: Scalars['ObjectID']['input'];
};


export type MutationsplitItemsIntoNewOrderArgs = {
  location: Scalars['ObjectID']['input'];
  partialOrders: Array<OrderWithSplittedItemsInput>;
  wholeOrders: Array<Scalars['ObjectID']['input']>;
};


export type MutationupdateAccountingJournalArgs = {
  id: Scalars['ObjectID']['input'];
  input: AccountingJournalInput;
};


export type MutationupdateCategoryArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateCategoryInput;
};


export type MutationupdateCurrentDeviceArgs = {
  input: UpdateCurrentDeviceInput;
};


export type MutationupdateCurrentRawMaterialArgs = {
  input: UpdateCurrentRawMaterialInput;
};


export type MutationupdateCurrentRawMaterialCategoryArgs = {
  input: UpdateCurrentRawMaterialCategoryInput;
};


export type MutationupdateCurrentRawMaterialReceivedArgs = {
  input: UpdateCurrentRawMaterialReceivedInput;
};


export type MutationupdateCurrentRawMaterialSupplierArgs = {
  input: UpdateCurrentRawMaterialSupplierInput;
};


export type MutationupdateCurrentVenueArgs = {
  input: UpdateCurrentVenueInput;
};


export type MutationupdateCurrentVenueAddOnsArgs = {
  input: UpdateCurrentVenueAddOnsInput;
};


export type MutationupdateCustomReportArgs = {
  input: UpdateCustomReportInput;
};


export type MutationupdateCustomerArgs = {
  id: Scalars['ObjectID']['input'];
  input: CustomerInput;
};


export type MutationupdateGeolocationArgs = {
  id: Scalars['ObjectID']['input'];
  input: GeolocationInput;
};


export type MutationupdateItemArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateItemInput;
};


export type MutationupdateItemRecipeArgs = {
  input: UpdateItemRecipeInput;
};


export type MutationupdateLocationArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateLocationInput;
};


export type MutationupdateMenuArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateMenuInput;
};


export type MutationupdateOfferArgs = {
  id: Scalars['ObjectID']['input'];
  input: OfferInput;
};


export type MutationupdateOptionArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateOptionInput;
};


export type MutationupdateOptionGroupArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateOptionGroupInput;
};


export type MutationupdateOptionRecipeArgs = {
  input: UpdateOptionRecipeInput;
};


export type MutationupdateOrderByIdArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateOrderByIdInput;
};


export type MutationupdateOrderStatusByIdArgs = {
  id: Scalars['ObjectID']['input'];
  status: ORDER_STATUS;
};


export type MutationupdatePrintLogArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdatePrintLogInput;
};


export type MutationupdateRawMaterialStatusArgs = {
  input: UpdateRawMaterialStatusInput;
};


export type MutationupdateRawMaterialUnitOfMeasurementArgs = {
  input: UpdateRawMaterialUnitOfMeasurementInput;
};


export type MutationupdateReservationArgs = {
  id: Scalars['ObjectID']['input'];
  input: ReservationInput;
};


export type MutationupdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationupdateTagArgs = {
  id: Scalars['ObjectID']['input'];
  input: TagInput;
};


export type MutationupdateVenueReceiptConfigArgs = {
  id: Scalars['ObjectID']['input'];
  input: UpdateVenueReceiptConfigInput;
};


export type MutationuploadRawMaterialReceivedArgs = {
  input: UploadRawMaterialReceivedInput;
};


export type MutationvoidReceiptArgs = {
  id: Scalars['ObjectID']['input'];
  message: Scalars['String']['input'];
};

export type Notification = {
  _device?: Maybe<Device>;
  _id: Scalars['ObjectID']['output'];
  _location: Location;
  _staff?: Maybe<User>;
  _venue: Venue;
  content?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  device?: Maybe<Scalars['ObjectID']['output']>;
  location: Scalars['ObjectID']['output'];
  staff?: Maybe<Scalars['ObjectID']['output']>;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type NotificationEvent = {
  id: Scalars['ObjectID']['output'];
  payload?: Maybe<Notification>;
  type: DATA_EVENT_TYPE;
};

export enum OFFER_CONFIGS_TYPE {
  categories = 'categories',
  item_types = 'item_types',
  items = 'items',
  receipts = 'receipts'
}

export enum OPS_PAGE {
  kds = 'kds',
  locations = 'locations',
  orders = 'orders',
  reservation = 'reservation',
  till = 'till'
}

export enum ORDER_CHANGELOG_TYPE {
  cancelled_item = 'cancelled_item',
  cancelled_order_moved = 'cancelled_order_moved',
  cancelled_order_no_items = 'cancelled_order_no_items',
  cancelled_order_split = 'cancelled_order_split',
  change_discount = 'change_discount',
  change_item_quantity = 'change_item_quantity',
  customer_accepted_prep_time_updates = 'customer_accepted_prep_time_updates',
  customer_rejected_prep_time_updates = 'customer_rejected_prep_time_updates',
  move_item_some = 'move_item_some',
  move_item_whole = 'move_item_whole',
  split_item_some = 'split_item_some',
  split_item_whole = 'split_item_whole',
  update_order_prep_time = 'update_order_prep_time'
}

export enum ORDER_ITEM_STATUS {
  delivered = 'delivered',
  preparing = 'preparing'
}

export enum ORDER_SOURCE {
  qr = 'qr',
  till = 'till'
}

export enum ORDER_STATUS {
  placed = 'placed',
  preparing = 'preparing',
  processed = 'processed'
}

export type Offer = {
  _id: Scalars['ObjectID']['output'];
  _venue: Venue;
  configs: Array<OfferConfig>;
  created_at: Scalars['DateTime']['output'];
  is_deactivated: Scalars['Boolean']['output'];
  is_offering_higher_value: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type OfferConfig = {
  _categories: Array<Category>;
  _items: Array<Item>;
  categories: Array<Scalars['ObjectID']['output']>;
  discount_type: DISCOUNT_TYPE;
  discount_value: Scalars['Float']['output'];
  item_types: Array<Scalars['String']['output']>;
  items: Array<Scalars['ObjectID']['output']>;
  type: OFFER_CONFIGS_TYPE;
};

export type OfferConfigInput = {
  categories?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  discount_type: DISCOUNT_TYPE;
  discount_value: Scalars['Float']['input'];
  item_types?: InputMaybe<Array<Scalars['String']['input']>>;
  items?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  type: OFFER_CONFIGS_TYPE;
};

export type OfferInput = {
  configs: Array<OfferConfigInput>;
  is_deactivated: Scalars['Boolean']['input'];
  is_offering_higher_value: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type Option = {
  _clone_from?: Maybe<Option>;
  _id: Scalars['ObjectID']['output'];
  _menu: Menu;
  _venue: Venue;
  clone_from?: Maybe<Scalars['ObjectID']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  is_template: Scalars['Boolean']['output'];
  listed_price: Scalars['Float']['output'];
  menu: Scalars['ObjectID']['output'];
  option_id: Scalars['String']['output'];
  original_price: Scalars['Float']['output'];
  original_price_addons: OriginalPriceAddons;
  recipe: Array<RecipeRawMaterialUsed>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type OptionGroup = {
  _clone_from?: Maybe<OptionGroup>;
  _id: Scalars['ObjectID']['output'];
  _menu: Menu;
  _venue: Venue;
  clone_from?: Maybe<Scalars['ObjectID']['output']>;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  menu: Scalars['ObjectID']['output'];
  options: Array<OptionGroupOption>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type OptionGroupOption = {
  _option: Option;
  option: Scalars['ObjectID']['output'];
  ordering_index: Scalars['Float']['output'];
};

export type OptionGroupOptionInput = {
  option: Scalars['ObjectID']['input'];
  ordering_index: Scalars['Float']['input'];
};

export type Order = {
  _cancelled_by?: Maybe<User>;
  _current_location?: Maybe<Location>;
  _customer?: Maybe<Customer>;
  _device?: Maybe<Device>;
  _id: Scalars['ObjectID']['output'];
  _location: Location;
  _original_orders: Array<Order>;
  _paid_by?: Maybe<User>;
  _prepared_by?: Maybe<User>;
  _processed_by?: Maybe<User>;
  _receipt?: Maybe<PrintLog>;
  _staff?: Maybe<User>;
  _user?: Maybe<User>;
  _venue: Venue;
  adjustment_amount: Scalars['Float']['output'];
  cancelled_amount: Scalars['Float']['output'];
  cancelled_at?: Maybe<Scalars['DateTime']['output']>;
  cancelled_by?: Maybe<Scalars['ObjectID']['output']>;
  cancelled_items: Array<OrderItem>;
  cancelled_reason?: Maybe<Scalars['String']['output']>;
  changelogs: Array<OrderChangelog>;
  created_at: Scalars['DateTime']['output'];
  currencies_configs: Scalars['JSON']['output'];
  current_location?: Maybe<Scalars['ObjectID']['output']>;
  customer?: Maybe<Scalars['ObjectID']['output']>;
  device?: Maybe<Scalars['ObjectID']['output']>;
  discount_amount: Scalars['Float']['output'];
  grand_total: Scalars['Float']['output'];
  gross_amount: Scalars['Float']['output'];
  index: Scalars['Int']['output'];
  is_bill_printed: Scalars['Boolean']['output'];
  is_cancelled: Scalars['Boolean']['output'];
  is_needing_payment_confirmation: Scalars['Boolean']['output'];
  is_needing_prep_time_confirmation: Scalars['Boolean']['output'];
  is_paid: Scalars['Boolean']['output'];
  is_request_payment_confirmation: Scalars['Boolean']['output'];
  items: Array<OrderItem>;
  items_count: Scalars['Int']['output'];
  items_count_by_types?: Maybe<Scalars['JSON']['output']>;
  location: Scalars['ObjectID']['output'];
  max_prepare_time: Scalars['Float']['output'];
  net_amount: Scalars['Float']['output'];
  note?: Maybe<Scalars['String']['output']>;
  offer_amount: Scalars['Float']['output'];
  original_orders: Array<Scalars['ObjectID']['output']>;
  paid_at?: Maybe<Scalars['DateTime']['output']>;
  paid_by?: Maybe<Scalars['ObjectID']['output']>;
  payway_ref?: Maybe<Scalars['String']['output']>;
  payway_verification_data?: Maybe<Scalars['JSON']['output']>;
  prep_time: Scalars['Float']['output'];
  prepared_at?: Maybe<Scalars['DateTime']['output']>;
  prepared_by?: Maybe<Scalars['ObjectID']['output']>;
  prepped_count: Scalars['Int']['output'];
  processed_at?: Maybe<Scalars['DateTime']['output']>;
  processed_by?: Maybe<Scalars['ObjectID']['output']>;
  receipt?: Maybe<Scalars['ObjectID']['output']>;
  service_charge_amount: Scalars['Float']['output'];
  source: ORDER_SOURCE;
  staff?: Maybe<Scalars['ObjectID']['output']>;
  status: ORDER_STATUS;
  subtotal: Scalars['Float']['output'];
  ticket_linebreaks: Array<Scalars['Int']['output']>;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<Scalars['ObjectID']['output']>;
  vat_amount: Scalars['Float']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type OrderChangelog = {
  _user?: Maybe<User>;
  created_at: Scalars['DateTime']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  new_value?: Maybe<Scalars['JSON']['output']>;
  old_value?: Maybe<Scalars['JSON']['output']>;
  type: ORDER_CHANGELOG_TYPE;
  updated_at: Scalars['DateTime']['output'];
  user?: Maybe<Scalars['ObjectID']['output']>;
};

export type OrderEvent = {
  id: Scalars['ObjectID']['output'];
  payload?: Maybe<Order>;
  type: DATA_EVENT_TYPE;
};

export type OrderItem = {
  _category: Category;
  _id: Scalars['ObjectID']['output'];
  _item: Item;
  _printer_tag?: Maybe<Tag>;
  cancelled_amount: Scalars['Float']['output'];
  cancelled_reason?: Maybe<Scalars['String']['output']>;
  category: Scalars['ObjectID']['output'];
  comments?: Maybe<Scalars['String']['output']>;
  delivered_count: Scalars['Int']['output'];
  extra_quantity: Scalars['Int']['output'];
  gross_amount: Scalars['Float']['output'];
  is_paid: Scalars['Boolean']['output'];
  item: Scalars['ObjectID']['output'];
  item_id: Scalars['String']['output'];
  listed_price: Scalars['Float']['output'];
  minimum_required_price: Scalars['Float']['output'];
  net_amount: Scalars['Float']['output'];
  no_service_charge: Scalars['Boolean']['output'];
  no_vat: Scalars['Boolean']['output'];
  options: Array<OrderItemOption>;
  original_price: Scalars['Float']['output'];
  original_price_addons: OriginalPriceAddons;
  prep_time: Scalars['Float']['output'];
  printer_tag?: Maybe<Scalars['ObjectID']['output']>;
  quantity: Scalars['Int']['output'];
  recipe: Array<ConsumedRecipeRawMaterialUsed>;
  serving_quantity: Scalars['Int']['output'];
  status: ORDER_ITEM_STATUS;
  subtotal: Scalars['Float']['output'];
  subtotal_addons: OrderItemSubtotalAddOns;
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unit_price: Scalars['Float']['output'];
};

export type OrderItemOption = {
  _id: Scalars['ObjectID']['output'];
  _option: Option;
  cancelled_amount: Scalars['Float']['output'];
  comments?: Maybe<Scalars['String']['output']>;
  gross_amount: Scalars['Float']['output'];
  is_hide_from_receipt: Scalars['Boolean']['output'];
  listed_price: Scalars['Float']['output'];
  net_amount: Scalars['Float']['output'];
  option: Scalars['ObjectID']['output'];
  option_id: Scalars['String']['output'];
  original_price: Scalars['Float']['output'];
  original_price_addons: OriginalPriceAddons;
  quantity: Scalars['Int']['output'];
  recipe: Array<ConsumedRecipeRawMaterialUsed>;
  serving_quantity: Scalars['Int']['output'];
  subtotal: Scalars['Float']['output'];
  subtotal_addons: OrderItemSubtotalAddOns;
  title: Scalars['String']['output'];
  uid: Scalars['String']['output'];
};

export type OrderItemSubtotalAddOns = {
  adjustment: OrderItemSubtotalAddOnsAdjustment;
  discount: OrderItemSubtotalAddOnsDiscount;
  offer: OrderItemSubtotalAddOnsOffer;
  service_charge: OrderItemSubtotalAddOnsServiceCharge;
  vat: OrderItemSubtotalAddOnsVat;
};

export type OrderItemSubtotalAddOnsAdjustment = {
  amount: Scalars['Float']['output'];
};

export type OrderItemSubtotalAddOnsDiscount = {
  amount: Scalars['Float']['output'];
  is_divided: Scalars['Boolean']['output'];
  type: DISCOUNT_TYPE;
  value: Scalars['Float']['output'];
};

export type OrderItemSubtotalAddOnsOffer = {
  amount: Scalars['Float']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
};

export type OrderItemSubtotalAddOnsServiceCharge = {
  amount: Scalars['Float']['output'];
  is_included: Scalars['Boolean']['output'];
  percentage: Scalars['Float']['output'];
};

export type OrderItemSubtotalAddOnsVat = {
  amount: Scalars['Float']['output'];
  is_included: Scalars['Boolean']['output'];
  percentage: Scalars['Float']['output'];
};

export type OrderRecipeRawMaterialUsedInput = {
  consumed_quantity: Scalars['Float']['input'];
  consumed_unit_of_measurement: Scalars['ObjectID']['input'];
  raw_material: Scalars['ObjectID']['input'];
  unit_of_measurement: Scalars['ObjectID']['input'];
};

export type OrderWithSplittedItemsInput = {
  items: Array<OrderWithSplittedItemsItemInput>;
  orderId: Scalars['ObjectID']['input'];
};

export type OrderWithSplittedItemsItemInput = {
  _id: Scalars['ObjectID']['input'];
  quantity: Scalars['Int']['input'];
};

export type OrdersPaginatedResponse = {
  items: Array<Order>;
  total: Scalars['Int']['output'];
};

export type OriginalPriceAddons = {
  service_charge: OriginalPriceAddonsData;
  vat: OriginalPriceAddonsData;
};

export type OriginalPriceAddonsData = {
  amount: Scalars['Float']['output'];
  percentage: Scalars['Float']['output'];
};

export type OriginalPriceAddonsDataInput = {
  amount: Scalars['Float']['input'];
  percentage: Scalars['Float']['input'];
};

export type OriginalPriceAddonsInput = {
  service_charge: OriginalPriceAddonsDataInput;
  vat: OriginalPriceAddonsDataInput;
};

export enum PRINT_EVENT_TYPE {
  cancelled_order = 'cancelled_order',
  checklist = 'checklist',
  custom = 'custom',
  kitchen_message = 'kitchen_message',
  receipt = 'receipt',
  report = 'report',
  ticket = 'ticket'
}

export type PaymentInput = {
  cash_received: Array<CreateOrderPaymentCashRecordInput>;
  cash_returned: Array<CreateOrderPaymentCashRecordInput>;
  headcount: Scalars['Int']['input'];
  isInternalReceipt: Scalars['Boolean']['input'];
  payment_types: Array<CreateOrderPaymentRecordInput>;
  room_master_reference?: InputMaybe<CreateOrderPaymentRoomMasterInput>;
  tips: Array<CreateOrderPaymentRecordInput>;
};

export type PaymentTypeAcceptEvent = {
  id: Scalars['ObjectID']['output'];
};

export type PaymentsDetailsPerReceipt = {
  adjustment_amount: Scalars['Float']['output'];
  cancelled_amount: Scalars['Float']['output'];
  created_at: Scalars['String']['output'];
  customers_names: Array<Scalars['String']['output']>;
  discount_amount: Scalars['Float']['output'];
  gross_amount: Scalars['Float']['output'];
  headcount: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  kpay_payment_types: Array<Scalars['String']['output']>;
  kpay_refs: Array<Scalars['String']['output']>;
  location_name: Scalars['String']['output'];
  location_room_number?: Maybe<Scalars['String']['output']>;
  locations_groups_tags: Array<Scalars['ObjectID']['output']>;
  net_amount: Scalars['Float']['output'];
  offer_amount: Scalars['Float']['output'];
  offers_names: Array<Scalars['String']['output']>;
  orders_indexes: Array<Scalars['Int']['output']>;
  payment_types: Array<PrintLogPaymentRecord>;
  payway_refs: Array<Scalars['String']['output']>;
  receipt_number: Scalars['String']['output'];
  rounding_difference_amount: Scalars['Float']['output'];
  service_charge_amount: Scalars['Float']['output'];
  staff_name?: Maybe<Scalars['String']['output']>;
  status: RECEIPT_STATUS;
  subtotal: Scalars['Float']['output'];
  tips: Array<PrintLogPaymentRecord>;
  vat_amount: Scalars['Float']['output'];
};

export type PaymentsSummary = {
  averageHeadcountPerReceipt: Scalars['Float']['output'];
  averagePaymentPerHeadcount: Scalars['Float']['output'];
  averagePaymentPerReceipt: Scalars['Float']['output'];
  byPaymentTypes: Scalars['JSON']['output'];
  byReceiptTypes: PaymentsSummaryByReceiptTypes;
  headcount: Scalars['Float']['output'];
  kpayPaymentTypesBreakdown: Scalars['JSON']['output'];
  paymentsReceived: Scalars['Float']['output'];
  receiptsCount: PaymentsSummaryReceiptsCount;
  roundingDifference: Scalars['Float']['output'];
  tipsByPaymentTypes: Scalars['JSON']['output'];
  tipsReceived: Scalars['Float']['output'];
};

export type PaymentsSummaryByLocation = {
  _location: Location;
  location: Scalars['ObjectID']['output'];
  paymentsSummary: PaymentsSummary;
};

export type PaymentsSummaryByLocationGroupTag = {
  _tag: Tag;
  paymentsSummary: PaymentsSummary;
  tag: Scalars['ObjectID']['output'];
};

export type PaymentsSummaryByLocations = {
  byLocationGroupTags: Array<PaymentsSummaryByLocationGroupTag>;
  byLocations: Array<PaymentsSummaryByLocation>;
};

export type PaymentsSummaryByReceiptTypes = {
  external: PaymentsSummaryByReceiptTypesBreakdown;
  internal: PaymentsSummaryByReceiptTypesBreakdown;
  total: PaymentsSummaryByReceiptTypesBreakdown;
};

export type PaymentsSummaryByReceiptTypesBreakdown = {
  count: Scalars['Float']['output'];
  paymentsReceived: Scalars['Float']['output'];
};

export type PaymentsSummaryPerDay = {
  date: Scalars['String']['output'];
  endOfDay: Scalars['String']['output'];
  paymentsSummary: PaymentsSummary;
  startOfDay: Scalars['String']['output'];
};

export type PaymentsSummaryReceiptsCount = {
  nonVoid: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  void: Scalars['Float']['output'];
};

export type PreSignedUrlResponse = {
  publicUrl: Scalars['URL']['output'];
  uploadUrl: Scalars['URL']['output'];
};

export type PrintEvent = {
  contents: Scalars['String']['output'];
  itemType?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['ObjectID']['output']>;
  printer_name?: Maybe<Scalars['String']['output']>;
  tagId?: Maybe<Scalars['ObjectID']['output']>;
  type: PRINT_EVENT_TYPE;
  venueId: Scalars['ObjectID']['output'];
};

export type PrintLog = {
  _id: Scalars['ObjectID']['output'];
  _location: Location;
  _orders: Array<Order>;
  _venue: Venue;
  cash_received: Array<PrintLogCashPaymentRecord>;
  cash_returned: Array<PrintLogCashPaymentRecord>;
  created_at: Scalars['DateTime']['output'];
  headcount: Scalars['Int']['output'];
  is_void: Scalars['Boolean']['output'];
  location: Scalars['ObjectID']['output'];
  payment_types: Array<PrintLogPaymentRecord>;
  receipt_number: Scalars['String']['output'];
  room_master_reference?: Maybe<PrintLogRoomMasterRef>;
  rounding_difference_amount: Scalars['Float']['output'];
  tips: Array<PrintLogPaymentRecord>;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type PrintLogCashPaymentRecord = {
  amount: Scalars['Float']['output'];
  code: Scalars['String']['output'];
};

export type PrintLogPaymentRecord = {
  amount: Scalars['Float']['output'];
  code: Scalars['String']['output'];
  kpay_data?: Maybe<Scalars['JSON']['output']>;
  payment_type: Scalars['String']['output'];
  payway_data?: Maybe<Scalars['JSON']['output']>;
};

export type PrintLogRoomMasterRef = {
  folio_id: Scalars['String']['output'];
  folio_number: Scalars['String']['output'];
  room_number: Scalars['String']['output'];
};

export type PrintLogsPaginatedResponse = {
  items: Array<PrintLog>;
  total: Scalars['Int']['output'];
};

export type PublishPrintEventInput = {
  contents: Scalars['String']['input'];
  locationId?: InputMaybe<Scalars['ObjectID']['input']>;
  printer_name?: InputMaybe<Scalars['String']['input']>;
  tagId?: InputMaybe<Scalars['ObjectID']['input']>;
  type: PRINT_EVENT_TYPE;
  venueId: Scalars['ObjectID']['input'];
};

export type Query = {
  _accountingJournals: Array<AccountingJournal>;
  _categories: Array<Category>;
  _counters: Array<Counter>;
  _customers: Array<Customer>;
  _devices: Array<Device>;
  _geolocations: Array<Geolocation>;
  _items: Array<Item>;
  _locations: Array<Location>;
  _menus: Array<Menu>;
  _mongo_find_options: Array<Option>;
  _notifications: Array<Notification>;
  _offers: Array<Offer>;
  _orders: Array<Order>;
  _printLogs: Array<PrintLog>;
  _rawMaterial: Array<RawMaterial>;
  _rawMaterialReceived: Array<RawMaterialReceived>;
  _rawMaterialSupplier: Array<RawMaterialSupplier>;
  _rawMaterialUnitsOfMeasurement: Array<RawMaterialUnitOfMeasurement>;
  _reservations: Array<Reservation>;
  _roles: Array<Role>;
  _tags: Array<Tag>;
  _tempOrders: Array<TempOrder>;
  _users: Array<User>;
  _venuePrinters: Array<VenuePrinter>;
  _venues: Array<Venue>;
  getAccountingJournals: Array<AccountingJournal>;
  getActiveMenuForPos: Menu;
  getActiveMenuForQr: Menu;
  getCategories: Array<Category>;
  getCurrentDevice: Device;
  getCurrentLocation: Location;
  getCurrentVenue: Venue;
  getCustomReportByVenue: Array<CustomReport>;
  getCustomerById: Customer;
  getCustomerByUid: Customer;
  getCustomerOrders: OrdersPaginatedResponse;
  getCustomers: GetCustomersResponse;
  getInventoryTransactionsSummary: InventoryTransactionsSummary;
  getItems: Array<Item>;
  getLocationById: Location;
  getMenus: Array<Menu>;
  getOfferById: Offer;
  getOfferOrders: OrdersPaginatedResponse;
  getOffers: Array<Offer>;
  getOperationalGeolocations: Array<Geolocation>;
  getOperationalLocations: Array<Location>;
  getOperationalNotifications: Array<Notification>;
  getOperationalOrders: GetOperationalOrdersResponse;
  getOptionGroups: Array<OptionGroup>;
  getOptions: Array<Option>;
  getOrderById: Order;
  getOrderByPayWayRef: Order;
  getOrders: OrdersPaginatedResponse;
  getPaymentsDetails: Array<PaymentsDetailsPerReceipt>;
  getPaymentsSummaryByLocations: PaymentsSummaryByLocations;
  getPaymentsSummaryDaily: Array<PaymentsSummaryPerDay>;
  getPaymentsSummaryOverall: PaymentsSummary;
  getPrintLogs: PrintLogsPaginatedResponse;
  getPrintableReportsItems?: Maybe<Scalars['String']['output']>;
  getPrintableReportsOverall?: Maybe<Scalars['String']['output']>;
  getRawMaterialByVenue: Array<RawMaterial>;
  getRawMaterialCategoryByVenue: Array<RawMaterialCategory>;
  getRawMaterialReceivedByVenue: RawMaterialReceivedList;
  getRawMaterialSupplierByVenue: Array<RawMaterialSupplier>;
  getRawMaterialUnitsOfMeasurement: Array<RawMaterialUnitOfMeasurement>;
  getReservations: Array<Reservation>;
  getRoles: Array<Role>;
  getSalesDetails: Array<SalesDetailsPerItem>;
  getSalesSummaryByCategories: SalesSummaryByCategories;
  getSalesSummaryByCustomer: SalesSummary;
  getSalesSummaryByItems: SalesSummaryByItems;
  getSalesSummaryByItemsByCustomer: SalesSummaryByItems;
  getSalesSummaryByLocations: SalesSummaryByLocations;
  getSalesSummaryByOffer: SalesSummary;
  getSalesSummaryByOptions: SalesSummaryByOptions;
  getSalesSummaryByOptionsPerItem: SalesSummaryByOptionsPerItem;
  getSalesSummaryByStaffs: Array<SalesSummaryByStaff>;
  getSalesSummaryCustomReports: SalesSummaryCustomReports;
  getSalesSummaryDaily: Array<SalesSummaryPerDay>;
  getSalesSummaryOverall: SalesSummary;
  getTags: Array<Tag>;
  getVenueReceiptConfig: VenueReceiptConfig;
  ping: Scalars['String']['output'];
};


export type Query_accountingJournalsArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_categoriesArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_countersArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_customersArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_devicesArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_geolocationsArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_itemsArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_locationsArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_menusArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_mongo_find_optionsArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_notificationsArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_offersArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_ordersArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_printLogsArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_rawMaterialArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_rawMaterialReceivedArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_rawMaterialSupplierArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_rawMaterialUnitsOfMeasurementArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_reservationsArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_rolesArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_tagsArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_tempOrdersArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_usersArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_venuePrintersArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type Query_venuesArgs = {
  input?: InputMaybe<SudoQueryInput>;
};


export type QuerygetCategoriesArgs = {
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  menuId?: InputMaybe<Scalars['ObjectID']['input']>;
};


export type QuerygetCustomerByIdArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QuerygetCustomerByUidArgs = {
  uid: Scalars['String']['input'];
};


export type QuerygetCustomerOrdersArgs = {
  date?: InputMaybe<DateInput>;
  from: Scalars['Int']['input'];
  id: Scalars['ObjectID']['input'];
  items?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  size: Scalars['Int']['input'];
};


export type QuerygetCustomersArgs = {
  from: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  size: Scalars['Int']['input'];
};


export type QuerygetInventoryTransactionsSummaryArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetItemsArgs = {
  categoryId?: InputMaybe<Scalars['ObjectID']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  menuId?: InputMaybe<Scalars['ObjectID']['input']>;
};


export type QuerygetLocationByIdArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QuerygetMenusArgs = {
  id?: InputMaybe<Scalars['ObjectID']['input']>;
};


export type QuerygetOfferByIdArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QuerygetOfferOrdersArgs = {
  date?: InputMaybe<DateInput>;
  from: Scalars['Int']['input'];
  id: Scalars['ObjectID']['input'];
  size: Scalars['Int']['input'];
};


export type QuerygetOptionGroupsArgs = {
  itemId?: InputMaybe<Scalars['ObjectID']['input']>;
  menuId?: InputMaybe<Scalars['ObjectID']['input']>;
};


export type QuerygetOptionsArgs = {
  categoryId?: InputMaybe<Scalars['ObjectID']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  itemId?: InputMaybe<Scalars['ObjectID']['input']>;
  menuId?: InputMaybe<Scalars['ObjectID']['input']>;
};


export type QuerygetOrderByIdArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QuerygetOrderByPayWayRefArgs = {
  ref: Scalars['String']['input'];
};


export type QuerygetOrdersArgs = {
  date?: InputMaybe<DateInput>;
  from: Scalars['Int']['input'];
  indices?: InputMaybe<Array<Scalars['Int']['input']>>;
  size: Scalars['Int']['input'];
};


export type QuerygetPaymentsDetailsArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetPaymentsSummaryByLocationsArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetPaymentsSummaryDailyArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetPaymentsSummaryOverallArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetPrintLogsArgs = {
  date?: InputMaybe<DateInput>;
  from: Scalars['Int']['input'];
  orderIndices?: InputMaybe<Array<Scalars['Int']['input']>>;
  receiptNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  size: Scalars['Int']['input'];
};


export type QuerygetPrintableReportsItemsArgs = {
  download: Scalars['Boolean']['input'];
  from: Scalars['DateTime']['input'];
  onlyItemsWithSales: Scalars['Boolean']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetPrintableReportsOverallArgs = {
  download: Scalars['Boolean']['input'];
  from: Scalars['DateTime']['input'];
  onlyItemsWithSales: Scalars['Boolean']['input'];
  selectedLocationGroupTags?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  selectedPaymentTypes?: InputMaybe<Array<Scalars['String']['input']>>;
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetRawMaterialReceivedByVenueArgs = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};


export type QuerygetReservationsArgs = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};


export type QuerygetRolesArgs = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QuerygetSalesDetailsArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetSalesSummaryByCategoriesArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetSalesSummaryByCustomerArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QuerygetSalesSummaryByItemsArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetSalesSummaryByItemsByCustomerArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QuerygetSalesSummaryByLocationsArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetSalesSummaryByOfferArgs = {
  id: Scalars['ObjectID']['input'];
};


export type QuerygetSalesSummaryByOptionsArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetSalesSummaryByOptionsPerItemArgs = {
  from: Scalars['DateTime']['input'];
  itemId: Scalars['ObjectID']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetSalesSummaryByStaffsArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetSalesSummaryCustomReportsArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetSalesSummaryDailyArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};


export type QuerygetSalesSummaryOverallArgs = {
  from: Scalars['DateTime']['input'];
  shift?: InputMaybe<Scalars['String']['input']>;
  to: Scalars['DateTime']['input'];
};

export enum RECEIPT_STATUS {
  NON_VOID = 'NON_VOID',
  VOID = 'VOID'
}

export enum ROLE_PERMISSIONS {
  AUTO_LOGOUT = 'AUTO_LOGOUT',
  CANCEL_ORDER_AFTER_BILL_PRINTED = 'CANCEL_ORDER_AFTER_BILL_PRINTED',
  CANCEL_ORDER_AFTER_PAYMENT = 'CANCEL_ORDER_AFTER_PAYMENT',
  CANCEL_ORDER_BEFORE_BILL_PRINTED = 'CANCEL_ORDER_BEFORE_BILL_PRINTED',
  CANCEL_ORDER_BEFORE_PAYMENT = 'CANCEL_ORDER_BEFORE_PAYMENT',
  CANCEL_RECEIPT = 'CANCEL_RECEIPT',
  CREATE_ORDER = 'CREATE_ORDER',
  EDIT_ORDER_AFTER_BILL_PRINTED = 'EDIT_ORDER_AFTER_BILL_PRINTED',
  EDIT_ORDER_AFTER_PAYMENT = 'EDIT_ORDER_AFTER_PAYMENT',
  EDIT_ORDER_BEFORE_BILL_PRINTED = 'EDIT_ORDER_BEFORE_BILL_PRINTED',
  EDIT_ORDER_BEFORE_PAYMENT = 'EDIT_ORDER_BEFORE_PAYMENT',
  OPEN_CASHDRAWER = 'OPEN_CASHDRAWER',
  VIEW_ORDER = 'VIEW_ORDER'
}

export enum ROLE_TYPE {
  admin = 'admin',
  junior_staff = 'junior_staff',
  manager = 'manager',
  staff = 'staff',
  super_admin = 'super_admin',
  supervisor = 'supervisor'
}

export type RawMaterial = {
  _category?: Maybe<Category>;
  _id: Scalars['ObjectID']['output'];
  _supplier?: Maybe<RawMaterialSupplier>;
  _total_balance_value: Scalars['Float']['output'];
  _unit_of_measurement?: Maybe<RawMaterialUnitOfMeasurement>;
  _venue?: Maybe<Venue>;
  category: Scalars['ObjectID']['output'];
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expiration_notification_threshold: Scalars['Float']['output'];
  is_enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  raw_price: Scalars['Float']['output'];
  replenishment_tolerance: Scalars['Float']['output'];
  required_in_stock_amount: Scalars['Float']['output'];
  shelf_life: Scalars['Float']['output'];
  storage_location?: Maybe<Scalars['String']['output']>;
  supplier: Scalars['ObjectID']['output'];
  total_adjustment_quantity: Scalars['Float']['output'];
  total_balance_quantity: Scalars['Float']['output'];
  total_consumed_quantity: Scalars['Float']['output'];
  total_defect_quantity: Scalars['Float']['output'];
  total_expired_quantity: Scalars['Float']['output'];
  total_received_quantity: Scalars['Float']['output'];
  total_wastage_quantity: Scalars['Float']['output'];
  unit_of_measurement: Scalars['ObjectID']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type RawMaterialBalanceInput = {
  _id: Scalars['ObjectID']['input'];
  adjustment_quantity: Scalars['Float']['input'];
  consumed_quantity: Scalars['Float']['input'];
  defect_quantity: Scalars['Float']['input'];
  expired_quantity: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  wastage_quantity: Scalars['Float']['input'];
};

export type RawMaterialCategory = {
  _id: Scalars['ObjectID']['output'];
  _venue?: Maybe<Venue>;
  name: Scalars['String']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type RawMaterialReceived = {
  _id: Scalars['ObjectID']['output'];
  _raw_material: RawMaterial;
  _receiver: User;
  _supplier: RawMaterialSupplier;
  _venue: Venue;
  adjustment_quantity: Scalars['Float']['output'];
  created_at: Scalars['DateTime']['output'];
  defect_quantity: Scalars['Float']['output'];
  expired_quantity: Scalars['Float']['output'];
  quantity: Scalars['Float']['output'];
  raw_material: Scalars['ObjectID']['output'];
  raw_price: Scalars['Float']['output'];
  received_unit_of_measurement: Scalars['ObjectID']['output'];
  receiver: Scalars['ObjectID']['output'];
  supplier: Scalars['ObjectID']['output'];
  transaction_date: Scalars['DateTime']['output'];
  unit_of_measurement_metadata: RawMaterialReceivedUnitOfMeasurement;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
  wastage_quantity: Scalars['Float']['output'];
};

export type RawMaterialReceivedList = {
  rawMaterialReceived: Array<RawMaterialReceived>;
  rowCount: Scalars['Float']['output'];
};

export type RawMaterialReceivedUnitOfMeasurement = {
  _id: Scalars['ObjectID']['output'];
  sub_units: Array<RawMaterialSupplierUnitOfMeasurementSubUnit>;
  title: Scalars['String']['output'];
};

export type RawMaterialSupplier = {
  _id: Scalars['ObjectID']['output'];
  _venue?: Maybe<Venue>;
  code: Scalars['String']['output'];
  contact_person: Scalars['String']['output'];
  country_of_origin: Scalars['String']['output'];
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone_number: Scalars['String']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type RawMaterialSupplierUnitOfMeasurementSubUnit = {
  _id: Scalars['ObjectID']['output'];
  created_at: Scalars['DateTime']['output'];
  rate: Scalars['Float']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
};

export type RawMaterialUnitOfMeasurement = {
  _id: Scalars['ObjectID']['output'];
  _venue: Venue;
  created_at: Scalars['DateTime']['output'];
  sub_units: Array<RawMaterialSupplierUnitOfMeasurementSubUnit>;
  title: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type RawMaterialUnitOfMeasurementInput = {
  _id: Scalars['ObjectID']['input'];
  sub_units: Array<RawMaterialUnitOfMeasurementSubUnitInput>;
  title: Scalars['String']['input'];
};

export type RawMaterialUnitOfMeasurementSubUnitInput = {
  _id: Scalars['ObjectID']['input'];
  rate: Scalars['Float']['input'];
  title: Scalars['String']['input'];
};

export type RecipeRawMaterialUsed = {
  _raw_material: RawMaterial;
  _unit_of_measurement: Scalars['ObjectID']['output'];
  consumed_quantity: Scalars['Float']['output'];
  consumed_unit_of_measurement: Scalars['ObjectID']['output'];
  raw_material: Scalars['ObjectID']['output'];
  unit_of_measurement: Scalars['ObjectID']['output'];
};

export type RecipeRawMaterialUsedInput = {
  consumed_quantity: Scalars['Float']['input'];
  consumed_unit_of_measurement: Scalars['ObjectID']['input'];
  raw_material: Scalars['ObjectID']['input'];
  unit_of_measurement: Scalars['ObjectID']['input'];
};

export type RefetchOperationalLocationsCommandEvent = {
  id: Scalars['ObjectID']['output'];
};

export type RefetchOperationalOrdersCommandEvent = {
  id: Scalars['ObjectID']['output'];
};

export type Reservation = {
  _id: Scalars['ObjectID']['output'];
  _venue: Venue;
  contact: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  number_of_people: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type ReservationInput = {
  contact: Scalars['String']['input'];
  date: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  number_of_people: Scalars['Int']['input'];
};

export type Role = {
  _id: Scalars['ObjectID']['output'];
  _user: User;
  _venue: Venue;
  created_at: Scalars['DateTime']['output'];
  deleted_at?: Maybe<Scalars['DateTime']['output']>;
  password: Scalars['String']['output'];
  permissions: Array<ROLE_PERMISSIONS>;
  type: ROLE_TYPE;
  updated_at: Scalars['DateTime']['output'];
  user: Scalars['ObjectID']['output'];
  username: Scalars['String']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type RoomMasterResponse = {
  Balance?: Maybe<Scalars['String']['output']>;
  BalanceDue?: Maybe<Scalars['String']['output']>;
  BookingId?: Maybe<Scalars['String']['output']>;
  CreditLimit?: Maybe<Scalars['String']['output']>;
  FolioList: Array<FolioList>;
  FolioNumber: Scalars['String']['output'];
  GroupId?: Maybe<Scalars['String']['output']>;
  GuestName?: Maybe<Scalars['String']['output']>;
  PropertyId: Scalars['String']['output'];
  RateCode?: Maybe<Scalars['String']['output']>;
  RoomId?: Maybe<Scalars['String']['output']>;
  RoomName?: Maybe<Scalars['String']['output']>;
  RoomNumber: Scalars['String']['output'];
  RoomType?: Maybe<Scalars['String']['output']>;
  Type: Scalars['String']['output'];
  status?: Maybe<RoomMasterStatus>;
};

export type RoomMasterStatus = {
  errorException?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  errorThrown?: Maybe<Scalars['Boolean']['output']>;
  message: Scalars['String']['output'];
  messagetype?: Maybe<Scalars['String']['output']>;
  postingId?: Maybe<Scalars['String']['output']>;
  propertyid: Scalars['String']['output'];
  status?: Maybe<Scalars['Int']['output']>;
  timestamp: Scalars['String']['output'];
  traceid: Scalars['String']['output'];
};

export enum SYSTEM_HEALTH_SUBSCRIPTION_EVENT_TYPE {
  init = 'init',
  new_version_available = 'new_version_available',
  shutdown = 'shutdown'
}

export type SalesDetailsPerItem = {
  adjustment_amount: Scalars['Float']['output'];
  cancelled_amount: Scalars['Float']['output'];
  cancelled_reason?: Maybe<Scalars['String']['output']>;
  category_title?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['String']['output'];
  customer_name?: Maybe<Scalars['String']['output']>;
  discount_amount: Scalars['Float']['output'];
  gross_amount: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  is_cancelled: Scalars['Boolean']['output'];
  is_paid: Scalars['Boolean']['output'];
  item_id: Scalars['String']['output'];
  item_title: Scalars['String']['output'];
  item_type: Scalars['String']['output'];
  kpay_payment_types: Array<Scalars['String']['output']>;
  kpay_ref?: Maybe<Scalars['String']['output']>;
  location_name: Scalars['String']['output'];
  location_room_number?: Maybe<Scalars['String']['output']>;
  locations_groups_tags: Array<Scalars['ObjectID']['output']>;
  net_amount: Scalars['Float']['output'];
  offer_amount: Scalars['Float']['output'];
  offer_name?: Maybe<Scalars['String']['output']>;
  order_index: Scalars['Int']['output'];
  order_source: Scalars['String']['output'];
  original_price: Scalars['Float']['output'];
  payment_types: Array<Scalars['String']['output']>;
  payway_ref?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Float']['output'];
  receipt_number?: Maybe<Scalars['String']['output']>;
  service_charge_amount: SalesDetailsPerItemAddOns;
  staff_name?: Maybe<Scalars['String']['output']>;
  status: ORDER_STATUS;
  subtotal: Scalars['Float']['output'];
  vat_amount: SalesDetailsPerItemAddOns;
};

export type SalesDetailsPerItemAddOns = {
  amount: Scalars['Float']['output'];
  is_included: Scalars['Boolean']['output'];
};

export type SalesSummary = {
  adjustment: SalesSummaryBreakdown;
  averageGrossAmountPerOrder: SalesSummaryBreakdown;
  cancelledValue: SalesSummaryBreakdown;
  discount: SalesSummaryBreakdown;
  grossSales: SalesSummaryBreakdown;
  netSales: SalesSummaryBreakdown;
  offer: SalesSummaryBreakdown;
  ordersCount: SalesSummaryBreakdown;
  serviceCharge: SalesSummaryBreakdown;
  subtotal: SalesSummaryBreakdown;
  vat: SalesSummaryBreakdown;
};

export type SalesSummaryBreakdown = {
  qr: Scalars['Float']['output'];
  till: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type SalesSummaryByCategories = {
  byCategories: Array<SalesSummaryByCategory>;
  count: Scalars['Float']['output'];
  grossSales: Scalars['Float']['output'];
};

export type SalesSummaryByCategory = {
  _category: Category;
  byItems: Array<SalesSummaryByItem>;
  cancelledValue: Scalars['Float']['output'];
  category: Scalars['ObjectID']['output'];
  count: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  grossSales: Scalars['Float']['output'];
  netSales: Scalars['Float']['output'];
  offer: Scalars['Float']['output'];
  serviceCharge: Scalars['Float']['output'];
  subtotal: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type SalesSummaryByItem = {
  _item: Item;
  cancelledValue: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  grossSales: Scalars['Float']['output'];
  item: Scalars['ObjectID']['output'];
  netSales: Scalars['Float']['output'];
  offer: Scalars['Float']['output'];
  serviceCharge: Scalars['Float']['output'];
  subtotal: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type SalesSummaryByItems = {
  byItems: Array<SalesSummaryByItem>;
  cancelled: SalesSummaryByItemsCancelled;
  count: SalesSummaryByItemsOverallBreakdown;
  grossSales: SalesSummaryByItemsOverallBreakdown;
};

export type SalesSummaryByItemsCancelled = {
  count: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type SalesSummaryByItemsOverallBreakdown = {
  byTypes: Scalars['JSON']['output'];
  total: Scalars['Float']['output'];
};

export type SalesSummaryByLocation = {
  _location: Location;
  location: Scalars['ObjectID']['output'];
  salesSummary: SalesSummary;
};

export type SalesSummaryByLocationGroupTag = {
  _tag: Tag;
  salesSummary: SalesSummary;
  tag: Scalars['ObjectID']['output'];
};

export type SalesSummaryByLocations = {
  byLocationGroupTags: Array<SalesSummaryByLocationGroupTag>;
  byLocations: Array<SalesSummaryByLocation>;
};

export type SalesSummaryByOption = {
  _option: Option;
  cancelledValue: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  grossSales: Scalars['Float']['output'];
  netSales: Scalars['Float']['output'];
  offer: Scalars['Float']['output'];
  option: Scalars['ObjectID']['output'];
  serviceCharge: Scalars['Float']['output'];
  subtotal: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type SalesSummaryByOptionPerItem = {
  _option: Option;
  count: Scalars['Float']['output'];
  option: Scalars['ObjectID']['output'];
};

export type SalesSummaryByOptions = {
  byOptions: Array<SalesSummaryByOption>;
  cancelled: SalesSummaryByOptionsCancelled;
  count: SalesSummaryByOptionsOverallBreakdown;
  grossSales: SalesSummaryByOptionsOverallBreakdown;
};

export type SalesSummaryByOptionsCancelled = {
  count: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type SalesSummaryByOptionsOverallBreakdown = {
  total: Scalars['Float']['output'];
};

export type SalesSummaryByOptionsPerItem = {
  byOptions: Array<SalesSummaryByOptionPerItem>;
  count: SalesSummaryByOptionsPerItemOverallBreakdown;
};

export type SalesSummaryByOptionsPerItemOverallBreakdown = {
  total: Scalars['Float']['output'];
};

export type SalesSummaryByStaff = {
  _staff: User;
  salesSummary: SalesSummary;
  staff: Scalars['ObjectID']['output'];
};

export type SalesSummaryCustom = {
  _id: Scalars['String']['output'];
  adjustment: Scalars['Float']['output'];
  cancelledValue: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  grossSales: Scalars['Float']['output'];
  netSales: Scalars['Float']['output'];
  offer: Scalars['Float']['output'];
  serviceCharge: Scalars['Float']['output'];
  subtotal: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type SalesSummaryCustomReports = {
  byCategories: Array<SalesSummaryCustom>;
  byItemTypes: Array<SalesSummaryCustom>;
  byItems: Array<SalesSummaryCustomUnit>;
  byLocationGroupTags: Array<SalesSummaryCustom>;
  byLocations: Array<SalesSummaryCustom>;
  byOffers: Array<SalesSummaryCustom>;
  byOptions: Array<SalesSummaryCustomUnit>;
  byPaymentTypes: Array<SalesSummaryCustom>;
};

export type SalesSummaryCustomUnit = {
  _id: Scalars['String']['output'];
  adjustment: Scalars['Float']['output'];
  cancelledValue: Scalars['Float']['output'];
  count: Scalars['Float']['output'];
  discount: Scalars['Float']['output'];
  grossSales: Scalars['Float']['output'];
  itemId: Scalars['String']['output'];
  netSales: Scalars['Float']['output'];
  offer: Scalars['Float']['output'];
  serviceCharge: Scalars['Float']['output'];
  subtotal: Scalars['Float']['output'];
  unitPrice: Scalars['Float']['output'];
  vat: Scalars['Float']['output'];
};

export type SalesSummaryPerDay = {
  date: Scalars['String']['output'];
  endOfDay: Scalars['String']['output'];
  salesSummary: SalesSummary;
  startOfDay: Scalars['String']['output'];
};

export type Subscription = {
  subscribeToCurrentVenue: VenueEvent;
  subscribeToLocation: LocationEvent;
  subscribeToMenu: MenuEvent;
  subscribeToOrder: OrderEvent;
  subscribeToOrderPaymentTypeAcceptEvent: PaymentTypeAcceptEvent;
  subscribeToPrintEvent: PrintEvent;
  subscribeToSystemHealth: SystemHealthEvent;
  subscribeToVenueLocations: LocationEvent;
  subscribeToVenueNotifications: NotificationEvent;
  subscribeToVenueOrders: OrderEvent;
  subscribeToVenueRefetchOperationalLocationsCommand: RefetchOperationalLocationsCommandEvent;
  subscribeToVenueRefetchOperationalOrdersCommand: RefetchOperationalOrdersCommandEvent;
};


export type SubscriptionsubscribeToLocationArgs = {
  id: Scalars['ObjectID']['input'];
};


export type SubscriptionsubscribeToMenuArgs = {
  id: Scalars['ObjectID']['input'];
};


export type SubscriptionsubscribeToOrderArgs = {
  orderId?: InputMaybe<Scalars['ObjectID']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionsubscribeToOrderPaymentTypeAcceptEventArgs = {
  id: Scalars['ObjectID']['input'];
};


export type SubscriptionsubscribeToSystemHealthArgs = {
  type?: InputMaybe<SYSTEM_HEALTH_SUBSCRIPTION_EVENT_TYPE>;
};

export type SubtotalAddOnsInput = {
  adjustment: CreateOrderItemSubtotalAddOnsAdjustmentInput;
  discount: CreateOrderItemSubtotalAddOnsDiscountInput;
  offer: CreateOrderItemSubtotalAddOnsOfferInput;
  service_charge: CreateOrderItemSubtotalAddOnsServiceChargeInput;
  vat: CreateOrderItemSubtotalAddOnsVatInput;
};

export type SudoQueryInput = {
  _id?: InputMaybe<Scalars['ObjectID']['input']>;
  _mongo_find_filters?: InputMaybe<Scalars['JSON']['input']>;
  _mongo_find_options?: InputMaybe<Scalars['JSON']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type SystemHealthEvent = {
  type: SYSTEM_HEALTH_SUBSCRIPTION_EVENT_TYPE;
};

export enum TAG_TYPE {
  location_group = 'location_group',
  printer = 'printer'
}

export type TableConfigValues = {
  fields: Array<CUSTOM_REPORT_VALUE_FIELD>;
  item: Scalars['String']['output'];
  label: Scalars['String']['output'];
  type: CUSTOM_REPORT_VALUE_TYPE;
};

export type TableConfigValuesInput = {
  fields: Array<CUSTOM_REPORT_VALUE_FIELD>;
  item: Scalars['String']['input'];
  label: Scalars['String']['input'];
  type: CUSTOM_REPORT_VALUE_TYPE;
};

export type TableConfigs = {
  title: Scalars['String']['output'];
  values: Array<TableConfigValues>;
};

export type Tag = {
  _id: Scalars['ObjectID']['output'];
  _venue: Venue;
  created_at: Scalars['DateTime']['output'];
  label: Scalars['String']['output'];
  type: TAG_TYPE;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type TagInput = {
  label: Scalars['String']['input'];
  type: TAG_TYPE;
};

export type TempOrder = {
  _id: Scalars['ObjectID']['output'];
  created_at: Scalars['DateTime']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  payway_ref?: Maybe<Scalars['String']['output']>;
  payway_verification_data?: Maybe<Scalars['JSON']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export enum USER_GENDER {
  female = 'female',
  male = 'male',
  other = 'other'
}

export type UpdateCategoryInput = {
  clone_from?: InputMaybe<Scalars['ObjectID']['input']>;
  is_template?: InputMaybe<Scalars['Boolean']['input']>;
  menu?: InputMaybe<Scalars['ObjectID']['input']>;
  ordering_index?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCurrentDeviceInput = {
  fcm_token?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCurrentRawMaterialCategoryInput = {
  _id: Scalars['ObjectID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateCurrentRawMaterialInput = {
  _id: Scalars['ObjectID']['input'];
  category: Scalars['ObjectID']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiration_notification_threshold: Scalars['Float']['input'];
  is_enabled: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  raw_price: Scalars['Float']['input'];
  replenishment_tolerance: Scalars['Float']['input'];
  required_in_stock_amount: Scalars['Float']['input'];
  shelf_life: Scalars['Float']['input'];
  storage_location?: InputMaybe<Scalars['String']['input']>;
  supplier: Scalars['ObjectID']['input'];
  unit_of_measurement: Scalars['ObjectID']['input'];
};

export type UpdateCurrentRawMaterialReceivedInput = {
  _id: Scalars['ObjectID']['input'];
  adjustment_quantity: Scalars['Float']['input'];
  defect_quantity: Scalars['Float']['input'];
  expired_quantity: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  raw_material: Scalars['ObjectID']['input'];
  raw_price: Scalars['Float']['input'];
  received_unit_of_measurement: Scalars['String']['input'];
  supplier: Scalars['ObjectID']['input'];
  transaction_date?: InputMaybe<Scalars['DateTime']['input']>;
  unit_of_measurement_metadata: RawMaterialUnitOfMeasurementInput;
  wastage_quantity: Scalars['Float']['input'];
};

export type UpdateCurrentRawMaterialSupplierInput = {
  _id: Scalars['ObjectID']['input'];
  code: Scalars['String']['input'];
  contact_person: Scalars['String']['input'];
  country_of_origin: Scalars['String']['input'];
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
};

export type UpdateCurrentVenueAddOnsInput = {
  service_charge?: InputMaybe<UpdateCurrentVenueServiceChargeInput>;
  vat?: InputMaybe<UpdateCurrentVenueVatInput>;
};

export type UpdateCurrentVenueAvailablePrinterInput = {
  name: Scalars['String']['input'];
};

export type UpdateCurrentVenueCurrenciesConfigInput = {
  code: Scalars['String']['input'];
  is_deactivated: Scalars['Boolean']['input'];
  is_primary_currency: Scalars['Boolean']['input'];
  is_secondary_currency: Scalars['Boolean']['input'];
  previous_rate?: InputMaybe<Scalars['Float']['input']>;
  rate: Scalars['Float']['input'];
};

export type UpdateCurrentVenueCustomPaymentTypeInput = {
  is_deactivated: Scalars['Boolean']['input'];
  label: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateCurrentVenueCustomPrintingFieldInput = {
  label: Scalars['String']['input'];
};

export type UpdateCurrentVenueInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  allow_comments?: InputMaybe<Scalars['Boolean']['input']>;
  auto_print_checklist?: InputMaybe<Scalars['Boolean']['input']>;
  available_printers?: InputMaybe<Array<UpdateCurrentVenueAvailablePrinterInput>>;
  banner_img?: InputMaybe<Scalars['URL']['input']>;
  cancelled_reason_options?: InputMaybe<Array<Scalars['String']['input']>>;
  currencies_configs?: InputMaybe<Array<UpdateCurrentVenueCurrenciesConfigInput>>;
  custom_note_instructions?: InputMaybe<Scalars['String']['input']>;
  custom_payment_types?: InputMaybe<Array<UpdateCurrentVenueCustomPaymentTypeInput>>;
  custom_printing_fields?: InputMaybe<Array<UpdateCurrentVenueCustomPrintingFieldInput>>;
  default_ops_page?: InputMaybe<OPS_PAGE>;
  description?: InputMaybe<Scalars['String']['input']>;
  is_accounting_journal_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_custom_report_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_dual_screen_mode_enable?: InputMaybe<Scalars['Boolean']['input']>;
  is_grouping_option_on_print_ticket?: InputMaybe<Scalars['Boolean']['input']>;
  is_internal_receipt_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_inventory_management_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_kpay_integration_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_language_localization_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_ordering_requires_customer?: InputMaybe<Scalars['Boolean']['input']>;
  is_ordering_requires_note?: InputMaybe<Scalars['Boolean']['input']>;
  is_payment_confirmation_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_payment_rounding_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_payway_integration_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_print_ticket_by_item?: InputMaybe<Scalars['Boolean']['input']>;
  is_print_ticket_by_option?: InputMaybe<Scalars['Boolean']['input']>;
  is_qr_ordering_only?: InputMaybe<Scalars['Boolean']['input']>;
  is_required_headcount_before_payment?: InputMaybe<Scalars['Boolean']['input']>;
  is_room_master_integration_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_till_send_and_pay_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_till_send_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  item_types?: InputMaybe<Array<Scalars['String']['input']>>;
  logo?: InputMaybe<Scalars['URL']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  operational_data_refetch_interval?: InputMaybe<Scalars['Int']['input']>;
  ops_page_font_size?: InputMaybe<Scalars['Float']['input']>;
  order_notification_dings?: InputMaybe<Scalars['Int']['input']>;
  preset_messages?: InputMaybe<Array<Scalars['String']['input']>>;
  print_content_padding?: InputMaybe<Array<Scalars['Float']['input']>>;
  print_font_sizes?: InputMaybe<UpdateCurrentVenuePrintFontSizesInput>;
  room_master_property_id?: InputMaybe<Scalars['String']['input']>;
  shifts?: InputMaybe<Array<UpdateCurrentVenueShiftInput>>;
  should_aggregate_tips_into_total_gross_sales?: InputMaybe<Scalars['Boolean']['input']>;
  stations?: InputMaybe<Array<UpdateCurrentVenueStationInput>>;
  theme?: InputMaybe<UpdateCurrentVenueThemeInput>;
  token?: InputMaybe<Scalars['String']['input']>;
  vat_tin?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCurrentVenuePrintFontSizesInput = {
  receipt: Scalars['Float']['input'];
  reports: Scalars['Float']['input'];
  ticket: Scalars['Float']['input'];
};

export type UpdateCurrentVenueServiceChargeInput = {
  is_service_charge_buried: Scalars['Boolean']['input'];
  service_charge: Scalars['Float']['input'];
};

export type UpdateCurrentVenueShiftInput = {
  endTime: Scalars['String']['input'];
  startTime: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type UpdateCurrentVenueStationInput = {
  _id: Scalars['ObjectID']['input'];
  locations_of_interest: Array<Scalars['String']['input']>;
  printer_name: Scalars['String']['input'];
  printer_tags_of_interest: Array<Scalars['ObjectID']['input']>;
  station_name: Scalars['String']['input'];
  types_of_interest: Array<Scalars['String']['input']>;
};

export type UpdateCurrentVenueThemeInput = {
  primary_color: Scalars['HexColorCode']['input'];
  primary_text_color: Scalars['HexColorCode']['input'];
};

export type UpdateCurrentVenueVatInput = {
  is_vat_buried: Scalars['Boolean']['input'];
  vat: Scalars['Float']['input'];
};

export type UpdateCustomReportInput = {
  _id: Scalars['ObjectID']['input'];
  display_type?: InputMaybe<CUSTOM_REPORT_DISPLAY_TYPE>;
  doughnut_configs?: InputMaybe<CustomReportDoughnutInput>;
  is_deleted?: InputMaybe<Scalars['Boolean']['input']>;
  table_configs?: InputMaybe<CustomReportTableConfigInput>;
};

export type UpdateItemInput = {
  banner_img?: InputMaybe<Scalars['URL']['input']>;
  category?: InputMaybe<Scalars['ObjectID']['input']>;
  clone_from?: InputMaybe<Scalars['ObjectID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  extra_quantity?: InputMaybe<Scalars['Int']['input']>;
  is_pos_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_qr_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_template?: InputMaybe<Scalars['Boolean']['input']>;
  item_id?: InputMaybe<Scalars['String']['input']>;
  listed_price?: InputMaybe<Scalars['Float']['input']>;
  menu?: InputMaybe<Scalars['ObjectID']['input']>;
  minimum_required_price?: InputMaybe<Scalars['Float']['input']>;
  no_service_charge?: InputMaybe<Scalars['Boolean']['input']>;
  no_vat?: InputMaybe<Scalars['Boolean']['input']>;
  option_groups?: InputMaybe<Array<ItemOptionGroupInput>>;
  options?: InputMaybe<Array<ItemOptionInput>>;
  ordering_index?: InputMaybe<Scalars['Int']['input']>;
  original_price?: InputMaybe<Scalars['Float']['input']>;
  original_price_addons?: InputMaybe<OriginalPriceAddonsInput>;
  prep_time?: InputMaybe<Scalars['Float']['input']>;
  printer_tag?: InputMaybe<Scalars['ObjectID']['input']>;
  recipe?: InputMaybe<Array<RecipeRawMaterialUsedInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateItemRecipeInput = {
  item_id: Scalars['ObjectID']['input'];
  recipe: Array<RecipeRawMaterialUsedInput>;
};

export type UpdateLocationHiddenItemsConfigsInput = {
  categories: Array<Scalars['ObjectID']['input']>;
  is_categories_hide_on_pos: Scalars['Boolean']['input'];
  is_categories_hide_on_qr: Scalars['Boolean']['input'];
  is_item_types_hide_on_pos: Scalars['Boolean']['input'];
  is_item_types_hide_on_qr: Scalars['Boolean']['input'];
  is_items_hide_on_pos: Scalars['Boolean']['input'];
  is_items_hide_on_qr: Scalars['Boolean']['input'];
  item_types: Array<Scalars['String']['input']>;
  items: Array<Scalars['ObjectID']['input']>;
};

export type UpdateLocationInput = {
  default_order_note?: InputMaybe<Scalars['String']['input']>;
  hidden_items_configs?: InputMaybe<UpdateLocationHiddenItemsConfigsInput>;
  is_pay_later_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  is_pay_now_allowed?: InputMaybe<Scalars['Boolean']['input']>;
  locations_groups_tags?: InputMaybe<Array<Scalars['ObjectID']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  no_service_charge?: InputMaybe<Scalars['Boolean']['input']>;
  no_vat?: InputMaybe<Scalars['Boolean']['input']>;
  payment_types?: InputMaybe<Array<Scalars['String']['input']>>;
  room_number?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<LOCATION_STATUS>;
  type?: InputMaybe<LOCATION_TYPE>;
};

export type UpdateMenuInput = {
  banner_img?: InputMaybe<Scalars['URL']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOptionGroupInput = {
  clone_from?: InputMaybe<Scalars['ObjectID']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  menu?: InputMaybe<Scalars['ObjectID']['input']>;
  options?: InputMaybe<Array<OptionGroupOptionInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOptionInput = {
  clone_from?: InputMaybe<Scalars['ObjectID']['input']>;
  deleted_at?: InputMaybe<Scalars['DateTime']['input']>;
  is_template?: InputMaybe<Scalars['Boolean']['input']>;
  listed_price?: InputMaybe<Scalars['Float']['input']>;
  menu?: InputMaybe<Scalars['ObjectID']['input']>;
  option_id?: InputMaybe<Scalars['String']['input']>;
  original_price?: InputMaybe<Scalars['Float']['input']>;
  original_price_addons?: InputMaybe<OriginalPriceAddonsInput>;
  recipe?: InputMaybe<Array<RecipeRawMaterialUsedInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOptionRecipeInput = {
  option_id: Scalars['ObjectID']['input'];
  recipe: Array<RecipeRawMaterialUsedInput>;
};

export type UpdateOrderByIdInput = {
  cancelled_items?: InputMaybe<Array<UpdateOrderItemInput>>;
  customer?: InputMaybe<Scalars['ObjectID']['input']>;
  items?: InputMaybe<Array<UpdateOrderItemInput>>;
  note?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderItemInput = {
  _id: Scalars['ObjectID']['input'];
  cancelled_amount: Scalars['Float']['input'];
  cancelled_reason?: InputMaybe<Scalars['String']['input']>;
  category: Scalars['ObjectID']['input'];
  comments?: InputMaybe<Scalars['String']['input']>;
  delivered_count: Scalars['Int']['input'];
  extra_quantity: Scalars['Int']['input'];
  gross_amount: Scalars['Float']['input'];
  is_paid: Scalars['Boolean']['input'];
  item: Scalars['ObjectID']['input'];
  item_id: Scalars['String']['input'];
  listed_price: Scalars['Float']['input'];
  minimum_required_price: Scalars['Float']['input'];
  net_amount: Scalars['Float']['input'];
  no_service_charge: Scalars['Boolean']['input'];
  no_vat: Scalars['Boolean']['input'];
  options: Array<CreateOrderItemOptionInput>;
  original_price: Scalars['Float']['input'];
  original_price_addons: OriginalPriceAddonsInput;
  prep_time: Scalars['Float']['input'];
  printer_tag?: InputMaybe<Scalars['ObjectID']['input']>;
  quantity: Scalars['Int']['input'];
  recipe: Array<OrderRecipeRawMaterialUsedInput>;
  serving_quantity: Scalars['Int']['input'];
  status: ORDER_ITEM_STATUS;
  subtotal: Scalars['Float']['input'];
  subtotal_addons: SubtotalAddOnsInput;
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
  unit_price: Scalars['Float']['input'];
};

export type UpdatePrintLogDiscountInput = {
  type: DISCOUNT_TYPE;
  value: Scalars['Float']['input'];
};

export type UpdatePrintLogInput = {
  adjustment_amount?: InputMaybe<Scalars['Float']['input']>;
  discount?: InputMaybe<UpdatePrintLogDiscountInput>;
  offerId?: InputMaybe<Scalars['ObjectID']['input']>;
  payment_types?: InputMaybe<Array<UpdatePrintLogPaymentTypeInput>>;
  receipt_number?: InputMaybe<Scalars['String']['input']>;
  service_charge?: InputMaybe<Scalars['Float']['input']>;
  vat?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdatePrintLogPaymentTypeInput = {
  amount: Scalars['Float']['input'];
  code: Scalars['String']['input'];
  payment_type: Scalars['String']['input'];
};

export type UpdateRawMaterialStatusInput = {
  _id: Scalars['ObjectID']['input'];
  is_enabled: Scalars['Boolean']['input'];
};

export type UpdateRawMaterialUnitOfMeasurementInput = {
  _id: Scalars['ObjectID']['input'];
  sub_units: Array<RawMaterialUnitOfMeasurementSubUnitInput>;
  title: Scalars['String']['input'];
};

export type UpdateRoleInput = {
  _id: Scalars['ObjectID']['input'];
  permissions: Array<ROLE_PERMISSIONS>;
  type: ROLE_TYPE;
  username: Scalars['String']['input'];
};

export type UpdateVenueReceiptConfigInput = {
  is_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  order_number?: InputMaybe<Scalars['Int']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  suffix_digit?: InputMaybe<Scalars['Int']['input']>;
};

export type UploadRawMaterialReceivedInput = {
  raw_material_balance: Array<RawMaterialBalanceInput>;
  raw_material_received: Array<CreateRawMaterialReceivedInput>;
};

export type User = {
  _id: Scalars['ObjectID']['output'];
  avatar?: Maybe<Scalars['URL']['output']>;
  created_at: Scalars['DateTime']['output'];
  first_name: Scalars['String']['output'];
  gender: USER_GENDER;
  last_name: Scalars['String']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['DateTime']['output'];
};

export type Venue = {
  _id: Scalars['ObjectID']['output'];
  _roles: Array<Role>;
  address: Scalars['String']['output'];
  allow_comments: Scalars['Boolean']['output'];
  auto_print_checklist: Scalars['Boolean']['output'];
  available_printers: Array<VenueAvailablePrinter>;
  banner_img: Scalars['URL']['output'];
  cancelled_reason_options: Array<Scalars['String']['output']>;
  created_at: Scalars['DateTime']['output'];
  currencies_configs: Array<VenueCurrenciesConfigs>;
  custom_note_instructions?: Maybe<Scalars['String']['output']>;
  custom_payment_types: Array<VenueCustomPaymentTypes>;
  custom_printing_fields: Array<VenueCustomPrintingField>;
  default_ops_page: OPS_PAGE;
  description: Scalars['String']['output'];
  fcm_tokens: Array<Scalars['String']['output']>;
  is_accounting_journal_enabled: Scalars['Boolean']['output'];
  is_custom_report_enabled: Scalars['Boolean']['output'];
  is_dual_screen_mode_enable: Scalars['Boolean']['output'];
  is_grouping_option_on_print_ticket: Scalars['Boolean']['output'];
  is_internal_receipt_enabled: Scalars['Boolean']['output'];
  is_inventory_management_enabled: Scalars['Boolean']['output'];
  is_kpay_integration_enabled: Scalars['Boolean']['output'];
  is_language_localization_enabled: Scalars['Boolean']['output'];
  is_ordering_requires_customer: Scalars['Boolean']['output'];
  is_ordering_requires_note: Scalars['Boolean']['output'];
  is_payment_confirmation_enabled: Scalars['Boolean']['output'];
  is_payment_rounding_enabled: Scalars['Boolean']['output'];
  is_payway_integration_enabled: Scalars['Boolean']['output'];
  is_print_ticket_by_item: Scalars['Boolean']['output'];
  is_print_ticket_by_option: Scalars['Boolean']['output'];
  is_qr_ordering_only: Scalars['Boolean']['output'];
  is_required_headcount_before_payment: Scalars['Boolean']['output'];
  is_room_master_integration_enabled: Scalars['Boolean']['output'];
  is_self_served: Scalars['Boolean']['output'];
  is_service_charge_buried: Scalars['Boolean']['output'];
  is_till_send_and_pay_enabled: Scalars['Boolean']['output'];
  is_till_send_enabled: Scalars['Boolean']['output'];
  is_vat_buried: Scalars['Boolean']['output'];
  item_types: Array<Scalars['String']['output']>;
  logo: Scalars['URL']['output'];
  name: Scalars['String']['output'];
  operational_data_refetch_interval: Scalars['Int']['output'];
  ops_page_font_size: Scalars['Float']['output'];
  order_notification_dings: Scalars['Int']['output'];
  preset_messages: Array<Scalars['String']['output']>;
  print_content_padding: Array<Scalars['Float']['output']>;
  print_font_sizes: VenuePrintFontSizes;
  room_master_property_id?: Maybe<Scalars['String']['output']>;
  service_charge: Scalars['Float']['output'];
  shifts: Array<VenueShifts>;
  should_aggregate_tips_into_total_gross_sales: Scalars['Boolean']['output'];
  stations: Array<VenueStation>;
  theme: VenueTheme;
  timezone: Scalars['TimeZone']['output'];
  updated_at: Scalars['DateTime']['output'];
  vat: Scalars['Float']['output'];
  vat_tin?: Maybe<Scalars['String']['output']>;
};

export type VenueAvailablePrinter = {
  name: Scalars['String']['output'];
};

export type VenueCurrenciesConfigs = {
  code: Scalars['String']['output'];
  is_deactivated: Scalars['Boolean']['output'];
  is_primary_currency: Scalars['Boolean']['output'];
  is_secondary_currency: Scalars['Boolean']['output'];
  previous_rate?: Maybe<Scalars['Float']['output']>;
  rate: Scalars['Float']['output'];
};

export type VenueCustomPaymentTypes = {
  is_deactivated: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type VenueCustomPrintingField = {
  label: Scalars['String']['output'];
};

export type VenueEvent = {
  id: Scalars['ObjectID']['output'];
  payload?: Maybe<Venue>;
  type: DATA_EVENT_TYPE;
};

export type VenuePrintFontSizes = {
  receipt: Scalars['Float']['output'];
  reports: Scalars['Float']['output'];
  ticket: Scalars['Float']['output'];
};

export type VenuePrinter = {
  _id: Scalars['ObjectID']['output'];
  _venue: Venue;
  available_printers: Array<VenueAvailablePrinter>;
  created_at: Scalars['DateTime']['output'];
  stations: Array<VenueStation>;
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type VenueReceiptConfig = {
  _id: Scalars['ObjectID']['output'];
  created_at: Scalars['DateTime']['output'];
  is_enabled: Scalars['Boolean']['output'];
  order_number: Scalars['Int']['output'];
  prefix?: Maybe<Scalars['String']['output']>;
  suffix_digit: Scalars['Int']['output'];
  updated_at: Scalars['DateTime']['output'];
  venue: Scalars['ObjectID']['output'];
};

export type VenueShifts = {
  endTime: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type VenueStation = {
  _id: Scalars['ObjectID']['output'];
  _printer_tags_of_interest: Array<Tag>;
  locations_of_interest: Array<Scalars['String']['output']>;
  printer_name: Scalars['String']['output'];
  printer_tags_of_interest: Array<Scalars['ObjectID']['output']>;
  station_name: Scalars['String']['output'];
  types_of_interest: Array<Scalars['String']['output']>;
};

export type VenueTheme = {
  primary_color: Scalars['HexColorCode']['output'];
  primary_text_color: Scalars['HexColorCode']['output'];
};
